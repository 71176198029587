import { Formik } from "formik";
import React, { useState } from "react";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { MdOutlineMailOutline } from "react-icons/md";
import {
  ChangeNewPasswordValidations,
  LoginValidations,
} from "../data/validations";
import { ChangeNewPasswordAPI, LoginUser } from "../controller/auth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

function ChangeNewPassword() {
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate(); // Initialize the navigate function
  const { setIsAuthenticated } = useAuth();
  const authToken = JSON.parse(localStorage.getItem("authToken"));

  const handlePasswordChange = async (values, { setFieldError }) => {
    ChangeNewPasswordAPI(values, authToken.token)
      .then((res) => {
        // navigate("/dashboard");
        toast.success(res.message+"." + " " + "Please Login with your New Password");
        navigate("/login");
      })
      .catch((error) => {
        console.log("error==>>>", error);
        navigate("/login");
        // console.log('email',error?.response?.data?.message);
        toast.error(error?.message);
      });
  };
  return (
    <div className="flex h-screen ">
      <div className="flex flex-wrap px-12 items-center">
        <div className="w-full md:w-1/2">
          <img src="/login_e.png" alt="" />
        </div>
        <div className="w-full md:w-1/2">
          <Formik
            initialValues={{
              newPassword: "",
              confirm_password: "",
            }}
            onSubmit={handlePasswordChange}
            validationSchema={ChangeNewPasswordValidations}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              values,
              errors,
              touched,
            }) => (
              <div className="bg-[#F4F9FF] rounded-2xl h-full">
                <div className="p-16">
                  <h3 className="text-[#000] font-poppins font-semibold text-2xl text-center">
                    Change Password
                  </h3>

                  <div className="flex justify-center">
                    <div className=" w-[50%]">
                      <div className="flex justify-center pt-6">
                        <input
                          placeholder="New Password"
                          name="newPassword"
                          onChange={handleChange("newPassword")}
                          onBlur={handleBlur("newPassword")}
                          value={values.newPassword}
                          className="border px-2 h-9 rounded-md border-[#8098F9] w-full"
                        />
                      </div>

                      <div>
                        {errors.newPassword && touched.newPassword ? (
                          <p className="text-[#ef4444] text-sm text-end">
                            {errors.newPassword}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <div className="relative w-[50%]">
                      <div className="flex justify-center pt-6">
                        <input
                          placeholder="Confirm Password"
                          name="confirm_password"
                          type={"text"}
                          value={values.confirm_password}
                          onBlur={handleBlur("confirm_password")}
                          onChange={handleChange("confirm_password")}
                          onKeyDown={(e) => {
                            if (e.key == "Enter") {
                              e.preventDefault();
                              handleSubmit();
                            }
                          }}
                          className="border px-2 h-9 rounded-md border-[#8098F9] w-full"
                        />
                      </div>

                      <div>
                        {errors.confirm_password && touched.confirm_password ? (
                          <p className="text-[#ef4444] text-sm text-end">
                            {errors.confirm_password}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-center items-center pt-12 w-full ">
                    <button
                      type="submit"
                      onClick={handleSubmit}
                      className="bg-[#3F7AFE]  py-2 rounded-md text-[#fff] w-[47%]"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default ChangeNewPassword;
