import React, { useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { FiInfo } from "react-icons/fi";
import { IoMdNotificationsOutline } from "react-icons/io";
import { IoEyeOutline } from "react-icons/io5";
import {
  AddNewComplaintApi,
  ViewSingleAdminComplaintsApi,
} from "../../controller/complaints";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Formik } from "formik";
import {
  AddBankValidations,
  AddNewCompanyValidations,
} from "../../data/validations";

function ViewSuperAdminComplaint() {
  const [age, setAge] = React.useState("");
  const [viewComplaint, setViewComplaint] = useState();
  console.log("viewComplaint==in super admin>>",viewComplaint)
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const authToken = JSON.parse(localStorage.getItem("authToken"));
  const token = authToken.token;
  const location = useLocation();
  const id = location.state;
  // console.log("location===-->>>",location?.state)

  useEffect(() => {
    setLoader(true);
    ViewSingleAdminComplaintsApi(location.state, token)
      .then((res) => {
        setLoader(false);
        // console.log("response in View complaint api", res);
        setViewComplaint(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const AddBankDetails = (values) => {
    console.log(values);
  };

  const EditComplaint = (values) => {
    console.log("values==--", values);
    AddNewComplaintApi(token, values, id)
      .then((res) => {
        // console.log("response in Add new complaint api", res);
        toast.success(res.message)
         
        navigate("/superadmin/complaint-management");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const handleADD = (values,{resetForm}) => {
  //   console.log("values===>>>", values);
  //   AddNewComplaintApi(token, values)
  //     .then((res) => {
  //       // console.log("response in Add new complaint api", res);
  //       toast.success(res.message)
  //       resetForm({});
  //       navigate("/admin/complaint-management");
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  return (
    <div>
      <div className="bg-[#FAFBFF] overflow-x-hidden">
        {/* Dashboard header start */}
        <div>
          <div className="flex justify-between items-center py-4 px-4 bg-[#FAFBFF]">
            <div>
              <span className="text-sm text-[#707EAE]">
                Pages / Complaint Management
              </span>
              <div>
                <span className="text-xl text-primary font-bold font-i">
                  Total Complaints
                </span>
              </div>
            </div>
            <div>
              <div className="bg-[#fff] flex items-center gap-3 p-3 rounded-full">
                <div className="relative">
                  <div>
                    <input
                      placeholder="Search"
                      className="border border-[#A3AED0] focus:outline-none rounded-full p-2"
                    />
                  </div>
                  <div className="absolute top-3 right-3">
                    <CiSearch size={20} className="text-[#A3AED0] font-bold" />
                  </div>
                </div>

                <div>
                  <IoMdNotificationsOutline
                    size={24}
                    className="text-[#A3AED0]"
                  />
                </div>
                <div>
                  <FiInfo size={24} className="text-[#A3AED0]" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Dashboard header end */}
      
        {loader ? (
          <p>Loading.....</p>
        ) : (
          <Formik
            initialValues={{
              first_name: viewComplaint?.consumer_Details?.first_name
                ? viewComplaint?.consumer_Details?.first_name
                : "",
              last_name: viewComplaint?.consumer_Details?.last_name
                ? viewComplaint?.consumer_Details?.last_name
                : "",
              complaint_number: viewComplaint?.complaint_Details?.complaint_number
                ? viewComplaint?.complaint_Details?.complaint_number
                : "",
              mobile_no: viewComplaint?.consumer_Details?.mobile_no
                ? viewComplaint?.consumer_Details?.mobile_no
                : "",
              email: viewComplaint?.consumer_Details?.email
                ? viewComplaint?.consumer_Details?.email
                : "",
              address: viewComplaint?.consumer_Details?.address
                ? viewComplaint?.consumer_Details?.address
                : "",
              city: viewComplaint?.consumer_Details?.city
                ? viewComplaint?.consumer_Details?.city
                : "",
              pincode: viewComplaint?.consumer_Details?.pincode
                ? viewComplaint?.consumer_Details?.pincode
                : "",
              district: viewComplaint?.consumer_Details?.district
                ? viewComplaint?.consumer_Details?.district
                : "",
              state: viewComplaint?.consumer_Details?.state
                ? viewComplaint?.consumer_Details?.state
                : "",
              country: viewComplaint?.consumer_Details?.country
                ? viewComplaint?.consumer_Details?.country
                : "",
              aadhar_card_no: viewComplaint?.consumer_Details?.aadhar_card_no
                ? viewComplaint?.consumer_Details?.aadhar_card_no
                : "",
              pan_card_no: viewComplaint?.consumer_Details?.pan_card_no
                ? viewComplaint?.consumer_Details?.pan_card_no
                : "",
              status: viewComplaint?.transaction_Details?.transaction_status
                ? viewComplaint?.transaction_Details?.transaction_status
                : "",
              date_time: viewComplaint?.transaction_Details?.transaction_date_time
                ? viewComplaint?.transaction_Details?.transaction_date_time
                : "",
              merchant_name: viewComplaint?.transaction_Details?.merchant_name
                ? viewComplaint?.transaction_Details?.merchant_name
                : "",
              merchant_vpa: viewComplaint?.transaction_Details?.merchant_vpa
                ? viewComplaint?.transaction_Details?.merchant_vpa
                : "",
              transaction_id: viewComplaint?.transaction_Details?.transaction_id
                ? viewComplaint?.transaction_Details?.transaction_id
                : "",
              payee_vpa: viewComplaint?.transaction_Details?.payee_vpa
                ? viewComplaint?.transaction_Details?.payee_vpa
                : "",
              transction_amount: viewComplaint?.transaction_Details
                ?.transaction_amount
                ? viewComplaint?.transaction_Details?.transaction_amount
                : "",
              utr_no: viewComplaint?.transaction_Details?.utr_no
                ? viewComplaint?.transaction_Details?.utr_no
                : "",
              complaint_category: viewComplaint?.complaint_Details
                ?.complaint_category
                ? viewComplaint?.complaint_Details?.complaint_category
                : "",
              complaint_sub_category: viewComplaint?.complaint_Details
                ?.complaint_sub_category
                ? viewComplaint?.complaint_Details?.complaint_sub_category
                : "",
            }}
            onSubmit={EditComplaint}
            validationSchema={AddNewCompanyValidations}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              values,
              errors,
              touched,
            }) => (
              <div className="border border-[#dfdfdf] rounded-xl p-4">
                <div className="pt-2">
                  <div>
                    <h3 className="text-primary font-poppins font-medium text-xl">
                      User Detail
                    </h3>
                  </div>
                </div>

                <div className="flex flex-wrap ">
                  <div className="w-full md:w-1/2">
                    <div className="m-3">
                      <div>
                        <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                          First Name
                        </h3>
                      </div>
                      <div className="mt-3">
                        <input
                          placeholder="First Name"
                          type="text"
                          name="first_name"
                          onChange={handleChange("first_name")}
                          onBlur={handleBlur("first_name")}
                          value={values.first_name}
                          className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                        />
                        {errors.first_name && touched.first_name ? (
                          <p className="text-[#ef4444]">{errors.first_name}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="w-full md:w-1/2">
                    <div className="m-3">
                      <div>
                        <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                          Last Name
                        </h3>
                      </div>
                      <div className="mt-3">
                        <input
                          placeholder="Mobile no."
                          type="text"
                          name="last_name"
                          onChange={handleChange("last_name")}
                          onBlur={handleBlur("last_name")}
                          value={values.last_name}
                          className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                        />
                        {errors.last_name && touched.last_name ? (
                          <p className="text-[#ef4444]">{errors.last_name}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-wrap ">
                  <div className="w-full md:w-1/2">
                    <div className="m-3">
                      <div>
                        <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                          Mobile No.
                        </h3>
                      </div>
                      <div className="mt-3">
                        <input
                          placeholder="Mobile No."
                          type="text"
                          name="mobile_no"
                          onChange={handleChange("mobile_no")}
                          onBlur={handleBlur("mobile_no")}
                          value={values.mobile_no}
                          className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                        />
                        {errors.mobile_no && touched.mobile_no ? (
                          <p className="text-[#ef4444]">{errors.mobile_no}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="w-full md:w-1/2">
                    <div className="m-3">
                      <div>
                        <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                          Email Id
                        </h3>
                      </div>
                      <div className="mt-3">
                        <input
                          placeholder="Email"
                          onChange={handleChange("email")}
                          onBlur={handleBlur("email")}
                          value={values.email}
                          className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                        />
                        {errors.email && touched.email ? (
                          <p className="text-[#ef4444]">{errors.email}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="w-full ">
                    <div className="m-3">
                      <div>
                        <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                          Address
                        </h3>
                      </div>
                      <div className="mt-3">
                        <input
                          placeholder="address"
                          onChange={handleChange("address")}
                          onBlur={handleBlur("address")}
                          value={values.address}
                          className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                        />
                        {errors.address && touched.address ? (
                          <p className="text-[#ef4444]">{errors.address}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex items-center">
                  <div className="w-full md:w-1/3">
                    <div className="m-3">
                      <div>
                        <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                          City
                        </h3>
                      </div>
                      <div className="mt-3">
                        <input
                          placeholder="City"
                          onChange={handleChange("city")}
                          onBlur={handleBlur("city")}
                          value={values.city}
                          className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                        />
                        {errors.city && touched.city ? (
                          <p className="text-[#ef4444]">{errors.city}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="w-full md:w-1/3">
                    <div className="m-3">
                      <div>
                        <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                          Pincode
                        </h3>
                      </div>
                      <div className="mt-3">
                        <input
                          placeholder="Pincode"
                          onChange={handleChange("pincode")}
                          onBlur={handleBlur("pincode")}
                          value={values.pincode}
                          className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                        />
                        {errors.pincode && touched.pincode ? (
                          <p className="text-[#ef4444]">{errors.pincode}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="w-full md:w-1/3">
                    <div className="m-3">
                      <div>
                        <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                          District
                        </h3>
                      </div>
                      <div className="mt-3">
                        <input
                          placeholder="District"
                          onChange={handleChange("district")}
                          onBlur={handleBlur("district")}
                          value={values.district}
                          className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                        />
                        {errors.district && touched.district ? (
                          <p className="text-[#ef4444]">{errors.district}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="flex flex-wrap ">
                    <div className="w-full md:w-1/2">
                      <div className="m-3">
                        <div>
                          <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                            State
                          </h3>
                        </div>
                        <div className="mt-3 flex items-center">
                          <div className="w-3/4">
                            <input
                              placeholder="State"
                              onChange={handleChange("state")}
                              onBlur={handleBlur("state")}
                              value={values.state}
                              className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                            />
                            {errors.state && touched.state ? (
                              <p className="text-[#ef4444]">{errors.state}</p>
                            ) : null}
                          </div>

                          <div className="w-1/4">
                            <div className="w-8 bg-[#E2ECFD]">
                              <div className="flex justify-center p-2">
                                <IoEyeOutline
                                  size={20}
                                  className="text-primary "
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="w-full md:w-1/2">
                      <div className="m-3">
                        <div>
                          <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                            Country
                          </h3>
                        </div>
                        <div className="mt-3 flex items-center">
                          <div className="w-3/4">
                            <input
                              placeholder="Payment ID"
                              onChange={handleChange("country")}
                              onBlur={handleBlur("country")}
                              value={values.country}
                              className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                            />
                            {errors.country && touched.country ? (
                              <p className="text-[#ef4444]">{errors.country}</p>
                            ) : null}
                          </div>

                          <div className="w-1/4">
                            <div className="w-8 bg-[#E2ECFD]">
                              <div className="flex justify-center p-2">
                                <IoEyeOutline
                                  size={20}
                                  className="text-primary "
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-wrap">
                    <div className="w-full md:w-1/2">
                      <div className="m-3">
                        <div>
                          <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                            Aadhar Card No.
                          </h3>
                        </div>
                        <div className="mt-3 flex items-center">
                          <div className="w-3/4">
                            <input
                              placeholder="Aadhar Card"
                              onChange={handleChange("aadhar_card_no")}
                              onBlur={handleBlur("aadhar_card_no")}
                              value={values.aadhar_card_no}
                              className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                            />
                            {errors.aadhar_card_no && touched.aadhar_card_no ? (
                              <p className="text-[#ef4444]">
                                {errors.aadhar_card_no}
                              </p>
                            ) : null}
                          </div>

                          <div className="w-1/4">
                            <div className="w-8 bg-[#E2ECFD]">
                              <div className="flex justify-center p-2">
                                <IoEyeOutline
                                  size={20}
                                  className="text-primary "
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="w-full md:w-1/2">
                      <div className="m-3">
                        <div>
                          <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                            Pan Card No.
                          </h3>
                        </div>
                        <div className="mt-3 flex items-center">
                          <div className="w-3/4">
                            <input
                              placeholder="Payment ID"
                              onChange={handleChange("pan_card_no")}
                              onBlur={handleBlur("pan_card_no")}
                              value={values.pan_card_no}
                              className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                            />
                            {errors.pan_card_no && touched.pan_card_no ? (
                              <p className="text-[#ef4444]">
                                {errors.pan_card_no}
                              </p>
                            ) : null}
                          </div>

                          <div className="w-1/4">
                            <div className="w-8 bg-[#E2ECFD]">
                              <div className="flex justify-center p-2">
                                <IoEyeOutline
                                  size={20}
                                  className="text-primary "
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="mt-4">
                      <h3 className="text-primary font-poppins font-medium text-xl">
                        Transaction Details
                      </h3>
                    </div>

                    <div className="flex flex-wrap ">
                      <div className="w-full lg:w-1/2">
                        <div className="p-2">
                          <div>
                            <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                              Transaction Status
                            </h3>
                          </div>
                          <div className="pt-3">
                            <input
                              placeholder="Transaction status"
                              onChange={handleChange("status")}
                              onBlur={handleBlur("status")}
                              value={values.status}
                              className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                            />
                            {errors.status && touched.status ? (
                              <p className="text-[#ef4444]">{errors.status}</p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="w-full lg:w-1/2">
                        <div className="p-2">
                          <div>
                            <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                              Transaction Date & Time
                            </h3>
                          </div>
                          <div className="pt-3">
                            <input
                              placeholder="Payment ID"
                              onChange={handleChange("date_time")}
                              onBlur={handleBlur("date_time")}
                              value={values.date_time}
                              className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                            />
                            {errors.date_time && touched.date_time ? (
                              <p className="text-[#ef4444]">
                                {errors.date_time}
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-wrap">
                      <div className="w-full md:w-1/2">
                        <div className="m-3">
                          <div>
                            <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                              Merchant Name
                            </h3>
                          </div>
                          <div className="mt-3 flex items-center">
                            <div className="w-full">
                              <input
                                placeholder="Payment ID"
                                onChange={handleChange("merchant_name")}
                                onBlur={handleBlur("merchant_name")}
                                value={values.merchant_name}
                                className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                              />
                              {errors.merchant_name && touched.merchant_name ? (
                                <p className="text-[#ef4444]">
                                  {errors.merchant_name}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="w-1/2">
                        <div className="m-3">
                          <div>
                            <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                              Merchant VPA
                            </h3>
                          </div>
                          <div className="mt-3 flex items-center">
                            <div className="w-full">
                              <input
                                placeholder="Merchant VPA"
                                onChange={handleChange("merchant_vpa")}
                                onBlur={handleBlur("merchant_vpa")}
                                value={values.merchant_vpa}
                                className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                              />
                              {errors.merchant_vpa && touched.merchant_vpa ? (
                                <p className="text-[#ef4444]">
                                  {errors.merchant_vpa}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-wrap">
                      <div className="w-full md:w-1/2">
                        <div className="m-3">
                          <div>
                            <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                              Transaction ID
                            </h3>
                          </div>
                          <div className="mt-3 flex items-center">
                            <div className="w-full">
                              <input
                                placeholder="Payment ID"
                                onChange={handleChange("transaction_id")}
                                onBlur={handleBlur("transaction_id")}
                                value={values.transaction_id}
                                className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                              />
                              {errors.transaction_id &&
                              touched.transaction_id ? (
                                <p className="text-[#ef4444]">
                                  {errors.transaction_id}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="m-full md:w-1/2">
                        <div className="m-3">
                          <div>
                            <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                              Payee VPA
                            </h3>
                          </div>
                          <div className="mt-3 flex items-center">
                            <div className="w-full">
                              <input
                                placeholder="Payment ID"
                                onChange={handleChange("payee_vpa")}
                                onBlur={handleBlur("payee_vpa")}
                                value={values.payee_vpa}
                                className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                              />
                              {errors.payee_vpa && touched.payee_vpa ? (
                                <p className="text-[#ef4444]">
                                  {errors.payee_vpa}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-wrap">
                      <div className="w-full md:w-1/2">
                        <div className="m-3">
                          <div>
                            <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                              Transaction Amount
                            </h3>
                          </div>
                          <div className="mt-3 flex items-center">
                            <div className="w-full">
                              <input
                                placeholder="Payment ID"
                                onChange={handleChange("transction_amount")}
                                onBlur={handleBlur("transction_amount")}
                                value={values.transction_amount}
                                className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                              />
                              {errors.transction_amount &&
                              touched.transction_amount ? (
                                <p className="text-[#ef4444]">
                                  {errors.transction_amount}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="m-full md:w-1/2">
                        <div className="m-3">
                          <div>
                            <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                              UTR No.
                            </h3>
                          </div>
                          <div className="mt-3 flex items-center">
                            <div className="w-full">
                              <input
                                placeholder="Payment ID"
                                onChange={handleChange("utr_no")}
                                onBlur={handleBlur("utr_no")}
                                value={values.utr_no}
                                className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                              />
                              {errors.utr_no && touched.utr_no ? (
                                <p className="text-[#ef4444]">
                                  {errors.utr_no}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="mt-4">
                      <h3 className="text-primary font-poppins font-medium text-xl">
                        Complaint Detail
                      </h3>
                    </div>

                    <div className="flex flex-wrap">
                      <div className="w-full md:w-1/2">
                        <div className="m-2">
                          <div>
                            <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                              Complaint Category
                            </h3>
                          </div>
                          <div className="pt-3">
                            <select
                              onChange={(e) =>
                                setFieldValue(
                                  "complaint_category",
                                  e.target.value
                                )
                              }
                              defaultValue={
                                viewComplaint?.complaint_Details
                                  ?.complaint_category
                              }
                              className="w-full border border-[#dfdfdf] focus:border-[#dfdfdf] focus:outline-none p-[0.35rem] cursor-pointer rounded-sm"
                            >
                              <option value={""} className="text-lg">
                                Select
                              </option>
                              <option
                                value={"Online Financial Fraud"}
                                className="text-lg"
                              >
                                Online Financial Fraud
                              </option>
                            </select>

                            <div>
                              {errors.complaint_category &&
                              touched.complaint_category ? (
                                <p className="text-[#ef4444]">
                                  {errors.complaint_category}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="w-full md:w-1/2">
                        <div className="m-2">
                          <div>
                            <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                              Complaint Sub Category
                            </h3>
                          </div>

                         
                          <div className="pt-3">
                            <select
                              onChange={(e) =>
                                setFieldValue(
                                  "complaint_sub_category",
                                  e.target.value
                                )
                              }
                              defaultValue={
                                viewComplaint?.complaint_Details
                                  ?.complaint_sub_category
                              }
                              className="w-full border border-[#dfdfdf] focus:border-[#dfdfdf] focus:outline-none p-[0.35rem] cursor-pointer rounded-sm"
                            >
                              <option  className="text-lg">
                                --Select--
                              </option>
                              <option
                                value={"Aadhar Enabled Payment System (AEPS)"}
                                className="text-lg"
                              >
                                Aadhar Enabled Payment System (AEPS)
                              </option>
                              <option
                                value={
                                  "Business Email Compromise/Email Takeover"
                                }
                                className="text-lg"
                              >
                                Business Email Compromise/Email Takeover
                              </option>
                              <option
                                value={"Debit/Credit Card Fraud/Sim Swap Fraud"}
                                className="text-lg"
                              >
                                Debit/Credit Card Fraud/Sim Swap Fraud
                              </option>
                              <option
                                value={"Demat/Depository Fraud"}
                                className="text-lg"
                              >
                                Demat/Depository Fraud
                              </option>
                              <option
                                value={"E-Wallet Related Fraud"}
                                className="text-lg"
                              >
                                E-Wallet Related Fraud
                              </option>
                              <option
                                value={"Fraud Call/Vishing"}
                                className="text-lg"
                              >
                                Fraud Call/Vishing
                              </option>
                              <option
                                value={"Internet Banking Related Fraud"}
                                className="text-lg"
                              >
                                Internet Banking Related Fraud
                              </option>
                              <option
                                value={"UPI Related Frauds"}
                                className="text-lg"
                              >
                                UPI Related Frauds
                              </option>
                            </select>

                            <div>
                              {errors.complaint_sub_category &&
                              touched.complaint_sub_category ? (
                                <p className="text-[#ef4444]">
                                  {errors.complaint_sub_category}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex items-center  justify-end gap-4 mt-8">
                  {/* <button className="border border-[#217EF9] text-[#217EF9] px-7 py-2 rounded-full">
                    Edit/Cancel
                  </button> */}
                  {/* <button
                    type="submit"
                    onClick={handleSubmit}
                    className="bg-gradient-to-r from-[#3C76D5] px-12 to-[#0AB9FC] py-2 rounded-full text-[#fff]"
                  >
                    Update
                  </button> */}
                </div>
              </div>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
}

export default ViewSuperAdminComplaint;
