import { Formik } from "formik";
import React, { useState } from "react";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { MdOutlineMailOutline } from "react-icons/md";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { LoginUser, VerifyEmail } from "../../controller/auth";
import { useAuth } from "../../context/AuthContext";
import { ForgotPasswordValidations, LoginValidations } from "../../data/validations";

function ForgotPasswordScreen() {
  const navigate = useNavigate(); // Initialize the navigate function
  const { setIsAuthenticated } = useAuth();
  const [loader, setLoader] = useState(false);

  const handlePasswordForgot = async (values, { setFieldError }) => {
    console.log("value--->>>",values)
    setLoader(true);
    // onClick={() => {
    //   navigate(`/superadmin/view-complaint`, {
    //     state: item?._id,
    //   });
    // }}
    VerifyEmail(values.email)
      .then((res) => {
        console.log("response in login api===>>>>>", res);
        setIsAuthenticated(true);
        toast.success(res?.message);
        navigate(`/verify-otp`, {
          state: values?.email,
        });
        setLoader(false);
        
       
      })
      .catch((error) => {
        setLoader(false);
        toast.error(error?.response?.data?.message);
      });
  };
  return (
    <div className="flex h-screen ">
      <div className="flex flex-wrap px-12 items-center">
        <div className="w-full md:w-1/2">
          <img src="/login_e.png" alt="" />
        </div>
        <div className="w-full md:w-1/2">
          <Formik
            initialValues={{
              email: "",
            }}
            onSubmit={handlePasswordForgot}
            validationSchema={ForgotPasswordValidations}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              values,
              errors,
              touched,
            }) => (
              <div className="bg-[#F4F9FF] rounded-2xl h-full">
                <div className="p-16">

                  <div className="flex justify-center">
                  <img src="/securafin.png" alt="" className="object-contain h-12 w-52"/>
                  </div>

                  <h3 className="text-[#000] font-poppins font-semibold text-2xl text-center pt-3">
                    Forgot Password
                  </h3>
                  

                  <div className="flex justify-center">
                    <div className="relative w-[50%]">
                      <div className="flex justify-center pt-6">
                        <input
                          placeholder="Email"
                          name="email"
                          onChange={handleChange("email")}
                          onBlur={handleBlur("email")}
                          value={values.email}
                          className="border px-2 h-9 rounded-md border-[#8098F9] w-full"
                        />
                      </div>
                      <div className="absolute top-8 right-4">
                        <MdOutlineMailOutline
                          size={20}
                          className="text-[#C4C4C4]"
                        />
                      </div>
                      <div>
                        {errors.email && touched.email ? (
                          <p className="text-[#ef4444] text-sm text-end">
                            {errors.email}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  

                  <div className="flex justify-center items-center pt-12 w-full ">
                    {loader ? (
                      <>
                        <button className="bg-[#3F7AFE] py-2 rounded-md text-[#fff] w-[47%]">
                          Loading....
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          onClick={handleSubmit}
                          className="bg-[#3F7AFE]  py-2 rounded-md text-[#fff] w-[47%]"
                        >
                          Submit
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default ForgotPasswordScreen;
