import axios from "axios";
import { base } from "../config";

export const AddBank = async (id, values, authToken) => {
  // console.log("in the add bank==>>>", values);
  const config = {
    headers: {
      Authorization: `${authToken}`,
    },
  };
  try {
    const { data } = await axios.post(
      `${base.API_URL}bank/register/${id ? `?id=${id}` : ""}`,
      values,
      config
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const AddCyberCell = async (id, values, authToken) => {
  // console.log("in the add bank==>>>", authToken);
  const config = {
    headers: {
      Authorization: `${authToken}`,
    },
  };
  try {
    const { data } = await axios.post(
      `${base.API_URL}cybercell/register/${id ? `?id=${id}` : ""}`,
      values,
      config
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const AddMerchantDetails = async (id,values, authToken) => {
  // console.log("in the add bank==>>>",authToken);
  const config = {
    headers: {
      Authorization: `${authToken}`,
    },
  };
  try {
    const { data } = await axios.post(
      `${base.API_URL}merchant/register/${id ? `?id=${id}` : ""}`,
      values,
      config
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const AddPaymentDetails = async (id,values, authToken) => {
  // console.log("in the Payment Details==>>>", values);
  const config = {
    headers: {
      Authorization: `${authToken}`,
    },
  };
  try {
    const { data } = await axios.post(
      `${base.API_URL}payment/register${id ? `?id=${id}` : ""}`,
      values,
      config
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const GetSelfCreatedOrganization = async (authToken) => {
  const config = {
    headers: {
      Authorization: `${authToken}`,
    },
  };
  try {
    const { data } = await axios.get(`${base.API_URL}user/self/org`, config);
    return data;
  } catch (error) {
    throw error;
  }
};
export const GetAllOrganization = async (authToken) => {
  const config = {
    headers: {
      Authorization: `${authToken}`,
    },
  };
  try {
    const { data } = await axios.get(`${base.API_URL}user/all/org`, config);
    return data;
  } catch (error) {
    throw error;
  }
};

export const GetAllBank = async (authToken) => {
  const config = {
    headers: {
      Authorization: `${authToken}`,
    },
  };
  try {
    const { data } = await axios.get(`${base.API_URL}user/all/org`, config);
    return data;
  } catch (error) {
    throw error;
  }
};

export const GetSingleOrganization = async (authToken, id) => {
  const config = {
    headers: {
      Authorization: `${authToken}`,
    },
  };
  try {
    const { data } = await axios.get(
      `${base.API_URL}user/view?id=${id}`,
      config
    );
    return data;
  } catch (error) {
    throw error;
  }
};
export const GetAllBanks = async (authToken, id) => {
  const config = {
    headers: {
      Authorization: `${authToken}`,
    },
  };
  try {
    const { data } = await axios.get(`${base.API_URL}banklist`, config);
    return data;
  } catch (error) {
    throw error;
  }
};
export const GetAllPaymentGatways = async (authToken, id) => {
  const config = {
    headers: {
      Authorization: `${authToken}`,
    },
  };
  try {
    const { data } = await axios.get(`${base.API_URL}payment/details`, config);
    return data;
  } catch (error) {
    throw error;
  }
};
export const GetAllMerchants = async (authToken, id) => {
  const config = {
    headers: {
      Authorization: `${authToken}`,
    },
  };
  try {
    const { data } = await axios.get(`${base.API_URL}merchant/details`, config);
    return data;
  } catch (error) {
    throw error;
  }
};
export const ChangeOrgStatus = async (authToken, id, value) => {
  const data1 = { id: id, status: value };
  const config = {
    headers: {
      Authorization: `${authToken}`,
    },
  };
  try {
    const { data } = await axios.post(
      `${base.API_URL}user/change/status`,
      data1,
      config
    );
    return data;
  } catch (error) {
    throw error;
  }
};
