import { Formik } from "formik";
import React, { useState } from "react";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { MdOutlineMailOutline } from "react-icons/md";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { LoginUser, SetNewPassword } from "../../controller/auth";
import { useAuth } from "../../context/AuthContext";
import {
  AddNewPasswordValidations,
  ForgotPasswordValidations,
  LoginValidations,
} from "../../data/validations";

function NewPasswordScreen() {
  const navigate = useNavigate(); // Initialize the navigate function
  const { setIsAuthenticated } = useAuth();
  const OTPToken = localStorage.getItem('OTPToken');
  // console.log("otptokennnnn----->>>>",OTPToken)
  const [loader, setLoader] = useState(false);

  

  const handlePasswordForgot = async (values, { setFieldError }) => {
    // console.log("value--->>>", values);
    setLoader(true);
    SetNewPassword(values.newPassword, OTPToken )
      .then((res) => {
        // console.log("response in login api===>>>>>", res);
        toast.success(res?.message);
        navigate("/")
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        toast.error(error?.response?.data?.message);
      });
  };
  return (
    <div className="flex h-screen ">
      <div className="flex flex-wrap px-12 items-center">
        <div className="w-full md:w-1/2">
          <img src="/login_e.png" alt="" />
        </div>
        <div className="w-full md:w-1/2">
          <Formik
            initialValues={{
              newPassword: "",
              confirmPassword: "",
            }}
            onSubmit={handlePasswordForgot}
            validationSchema={AddNewPasswordValidations}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              values,
              errors,
              touched,
            }) => (
              <div className="bg-[#F4F9FF] rounded-2xl h-full">
                <div className="p-16">
                  <div className="flex justify-center">
                    <img
                      src="/securafin.png"
                      alt=""
                      className="object-contain h-12 w-52"
                    />
                  </div>

                  <h3 className="text-[#000] font-poppins font-semibold text-2xl text-center pt-6">
                    New Password
                  </h3>

                  <div className="flex justify-center">
                    <div className="relative w-[50%]">
                      <div className="flex justify-center pt-6">
                        <div>
                          {/* <div className="pb-1">
                            <label className="font-poppins text-md">Enter OTP</label>
                          </div> */}
                          <div>
                            <input
                              placeholder="Enter New Password"
                              name="newPassword"
                              onChange={handleChange("newPassword")}
                              onBlur={handleBlur("newPassword")}
                              value={values.newPassword}
                              className="border px-2 h-9 rounded-md border-[#8098F9] w-full"
                            />
                          </div>
                        </div>
                      </div>
                      {/* <div className="absolute top-16 right-6">
                        <MdOutlineMailOutline
                          size={20}
                          className="text-[#C4C4C4]"
                        />
                      </div> */}
                      <div>
                        {errors.newPassword && touched.newPassword ? (
                          <p className="text-[#ef4444] text-sm text-end">
                            {errors.newPassword}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-center">
                    <div className="relative w-[50%]">
                      <div className="flex justify-center pt-6">
                        <div>
                            <input
                              placeholder="Confirm Password"
                              name="confirmPassword"
                              onChange={handleChange("confirmPassword")}
                              onBlur={handleBlur("confirmPassword")}
                              value={values.confirmPassword}
                              className="border px-2 h-9 rounded-md border-[#8098F9] w-full"
                            />
                        </div>
                      </div>
                      {/* <div className="absolute top-[3.7rem] right-6">
                        <MdOutlineMailOutline
                          size={20}
                          className="text-[#C4C4C4]"
                        />
                      </div> */}
                      <div>
                        {errors.confirmPassword && touched.confirmPassword ? (
                          <p className="text-[#ef4444] text-sm text-end">
                            {errors.confirmPassword}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-center items-center pt-12 w-full ">
                    {loader ? (
                      <>
                        <button className="bg-[#3F7AFE] py-2 rounded-md text-[#fff] w-[47%]">
                          Loading....
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          type="submit"
                          onClick={handleSubmit}
                          className="bg-[#3F7AFE]  py-2 rounded-md text-[#fff] w-[47%]"
                        >
                          Submit
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default NewPasswordScreen;
