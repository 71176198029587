import React from 'react'
import Dropdown from './Dropdown'

function DashboardHeader({title, subtitle}) {
  return (
    <div>
<div className="flex justify-between items-center py-4 px-4 bg-[#FAFBFF]">
  <div>
    <span className="text-sm text-[#707EAE]">
      {title}
    </span>
    <div>
      <span className="text-xl text-primary font-bold font-i">
        {subtitle}
      </span>
    </div>
  </div>

  <div>
    

    <Dropdown />
  </div>


</div>
</div>
  )
}

export default DashboardHeader