import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { AddReciverBankDetailsValidations, PaymentGatewayUpdateValidations } from "../data/validations";
import { GetAllBanks, GetAllMerchants, GetAllPaymentGatways } from "../controller/organization";
import ReactCustomSelect from "./ui/ReactCustomSelect";
import {
  AddPaymentAdminComplaintsApi,
  AddSwitchAdminComplaintsApi,
  AddSwitchBankAdminComplaintsApi,
} from "../controller/complaints";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const PaymentGateway = ({
  payment_gateway_status,
  viewComplaint,
  id,
  formState,
  handleSubmit,
}) => {
  console.log("viewComplaint-----------=333", viewComplaint);
  console.log("formState--=-=333", formState);
  const [allMerchants, setAllMerchants] = useState();
  console.log("payment_gateway_status==---", payment_gateway_status)
  const navigate = useNavigate();
  const authToken = localStorage.getItem("authToken");
  const token = JSON.parse(authToken);

  // AddSwitchBankAdminComplaintsApi

  const allPaymentGatways = [
    {
      id: 1,
      bankname: "Razor Pay",
    },

    {
      id: 2,
      bankname: "Singhtek Payment Gatway",
    },
  ];

  const customStyles = (hasError) => ({
    control: (provided, state) => ({
      ...provided,
      borderColor: hasError ? "red" : provided.borderColor,
      boxShadow: state.isFocused ? 0 : 0,
    }),
  });

  useEffect(() => {
    GetAllMerchants(token?.token)
      .then((res) => {
        setAllMerchants(res?.result);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleADD = (val) => {
    console.log("id- in payment Gateway form--->>", id, "value------>", val);
    const data = new FormData();
    data.append("transaction_status", val?.transaction_status);
    data.append("refund_status", val?.refund_status);
    data.append("transaction_failed_reason", val?.transaction_failed_reason);
    data.append("refund_reason", val?.refund_reason);
    data.append("merchant", val?.merchant);
    data.append("udf_no", val?.udf_no);
    data.append("transaction_remark", val?.transaction_remark);
    data.append("proof_attachment", val?.proof_attachment);
    AddPaymentAdminComplaintsApi(token.token, id, data)
      .then((res) => {
        toast.success(res.message);
        navigate("/admin/complaint-management");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div>
      <Formik
        initialValues={{
          transaction_status: payment_gateway_status ? payment_gateway_status?.transaction_status : "",
          refund_status: payment_gateway_status ? payment_gateway_status?.refund_status : "",
          transaction_failed_reason: payment_gateway_status ? payment_gateway_status?.transaction_failed_reason : "",
          refund_reason: payment_gateway_status ? payment_gateway_status?.refund_reason : "",
          merchant: payment_gateway_status ? payment_gateway_status?.merchant : "",
          udf_no: payment_gateway_status ? payment_gateway_status?.udf_no : "",
          proof_attachment: '',
          transaction_remark: payment_gateway_status ? payment_gateway_status?.transaction_remark : "",
          //   payment_gateway: "",
        }}
        onSubmit={handleADD}
        validationSchema={PaymentGatewayUpdateValidations}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          values,
          errors,
          touched,
        }) => (
          <div className=" rounded-xl p-4">
            <div className="pt-2 pb-3">
              <div>
                <h3 className="text-primary font-poppins font-medium text-xl">
                  Payment Gateway Status
                </h3>
              </div>
            </div>

            <div className="flex flex-wrap ">
              <div className="w-full md:w-1/2">
                <div className="p-2">
                  <div className="flex gap-1">
                    <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                      Transaction Status
                    </h3>
                    <div>
                      <span className=" font-bold text-[#ef4444]  text-red-500">
                        *
                      </span>
                    </div>
                  </div>

                  {/* {console.log("viewComplaint-999--=-=--=",viewComplaint)} */}
                  <div className="pt-3">
                    <select
                      onChange={(e) =>
                        setFieldValue("transaction_status", e.target.value)
                      }
                      className={`${
                        errors.transaction_status
                          ? "w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                          : "w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                      }`}
                      disabled={
                        payment_gateway_status?.transaction_status
                          ? true
                          : false
                      }
                      value={payment_gateway_status?.transaction_status}
                    >
                      <option className="text-lg">
                        --Select Transaction Status --
                      </option>
                      <option value={"pending"} className="text-lg">
                        Pending
                      </option>
                      <option value={"success"} className="text-lg">
                        Success
                      </option>
                      <option value={"reject"} className="text-lg">
                        Reject
                      </option>
                    </select>
                    {errors.transaction_status &&
                    touched.transaction_status ? (
                      <p className="text-[#ef4444]">
                        {errors.transaction_status}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="w-full md:w-1/2">
                <div className="p-2">
                  <div className="flex gap-1">
                    <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                      Refund Status
                    </h3>
                    <div>
                      <span className=" font-bold text-[#ef4444]  text-red-500">
                        *
                      </span>
                    </div>
                  </div>
                  <div className="pt-3">
                    <select
                      onChange={(e) =>
                        setFieldValue("refund_status", e.target.value)
                      }
                      className={`${
                        errors.refund_status
                          ? "w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                          : "w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                      }`}
                      disabled={
                        payment_gateway_status?.refund_status
                          ? true
                          : false
                      }
                      value={payment_gateway_status?.refund_status}
                    >
                      <option className="text-lg">
                        --Select Refund Status --
                      </option>
                      <option value={"yes"} className="text-lg">
                        Yes
                      </option>
                      <option value={"no"} className="text-lg">
                        No
                      </option>
                    </select>
                    {errors.refund_status &&
                    touched.refund_status ? (
                      <p className="text-[#ef4444]">
                        {errors.refund_status}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap ">
            {values?.transaction_status != "success" && (
              <div className="w-full md:w-1/2">
                <div className="m-3">
                  <div className="flex gap-1">
                    <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                      Transaction Failed Reason.
                    </h3>
                    <div>
                      <span className=" font-bold text-[#ef4444]  text-red-500">
                        *
                      </span>
                    </div>
                  </div>
                  <div className="mt-3">
                    <input
                      placeholder="Transaction Failed Reason."
                      onChange={handleChange("transaction_failed_reason")}
                      onBlur={handleBlur("transaction_failed_reason")}
                      value={values.transaction_failed_reason}
                      className={`${
                        errors.transaction_failed_reason
                          ? "w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                          : "w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                      }`}
                    />
                    {errors.transaction_failed_reason &&
                    touched.transaction_failed_reason ? (
                      <p className="text-[#ef4444]">
                        {errors.transaction_failed_reason}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            )}
              <div className="w-full md:w-1/2">
                <div className="m-3">
                  <div className="flex gap-1">
                    <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                      Refund Reason.
                    </h3>
                    <div>
                      <span className=" font-bold text-[#ef4444]  text-red-500">
                        *
                      </span>
                    </div>
                  </div>
                  <div className="mt-3">
                    <input
                      placeholder="Refund Reason."
                      onChange={handleChange("refund_reason")}
                      onBlur={handleBlur("refund_reason")}
                      value={values.refund_reason}
                      className={`${
                        errors.refund_reason
                          ? "w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                          : "w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                      }`}
                    />
                    {errors.refund_reason && touched.refund_reason ? (
                      <p className="text-[#ef4444]">{errors.refund_reason}</p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap ">
              <div className="w-full md:w-1/2">
                <div className="m-3">
                  <div className="flex gap-1">
                    <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                      Merchant.
                    </h3>
                    <div>
                      <span className=" font-bold text-[#ef4444]  text-red-500">
                        *
                      </span>
                    </div>
                  </div>
                  <div className="mt-3">
                    {/* <ReactCustomSelect
                      styles={customStyles(errors?.section?.message)}
                      options={allPaymentGatways}
                      multiSelect={true}
                      onchange={(item) =>
                        setFieldValue("switch_bank", item.bankname)
                      }
                    /> */}

                    {/* <select className="w-full h-9 border border-[#dfdfdf] px-1 rounded-md">
                      <option>Select Payment Gateway </option>
                      {allPaymentGateways?.map((item,index)=>{
                        {console.log(item, "item")}
                      <option >{item.role}</option>
                    })} 
                    </select> */}

                    <select
                      onChange={(e) => {
                        setFieldValue("merchant", e.target.value);
                      }}
                      disabled={
                        payment_gateway_status?.merchant
                          ? true
                          : false
                      }
                      value={payment_gateway_status?.merchant}
                      className="w-full h-9 border border-[#dfdfdf] px-1 rounded-md"
                    >
                      <option defaultChecked>Select Merchant</option>
                      {allMerchants?.map((item, index) => (
                        <option key={index} value={item?.merchant_name}>
                          {item?.merchant_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-1/2">
                <div className="m-3">
                  <div className="flex gap-1">
                    <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                      UDF No.
                    </h3>
                    <div>
                      <span className=" font-bold text-[#ef4444]  text-red-500">
                        *
                      </span>
                    </div>
                  </div>
                  <div className="mt-3">
                    <input
                      placeholder="UDF NO"
                      onChange={handleChange("udf_no")}
                      onBlur={handleBlur("udf_no")}
                      value={values.udf_no}
                      className={`${
                        errors.udf_no
                          ? "w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                          : "w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                      }`}
                    />
                    {errors.udf_no && touched.udf_no ? (
                      <p className="text-[#ef4444]">
                        {errors.udf_no}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap ">
              <div className="w-full md:w-1/2">
                <div className="m-3">
                  <div className="flex gap-1">
                    <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                      Transaction Remark.
                    </h3>
                    <div>
                      <span className=" font-bold text-[#ef4444]  text-red-500">
                        *
                      </span>
                    </div>
                  </div>
                  <div className="mt-3">
                    <input
                      placeholder="Transaction Remark."
                      onChange={handleChange("transaction_remark")}
                      onBlur={handleBlur("transaction_remark")}
                      value={values.transaction_remark}
                      className={`${
                        errors.transaction_remark
                          ? "w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                          : "w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                      }`}
                    />
                    {errors.transaction_remark && touched.transaction_remark ? (
                      <p className="text-[#ef4444]">
                        {errors.transaction_remark}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="w-full md:w-1/2">
                <div className="m-3">
                  <div className="flex gap-1">
                    <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                      Proof Attachment.
                    </h3>
                    <div>
                      <span className=" font-bold text-[#ef4444]  text-red-500">
                        *
                      </span>
                    </div>
                  </div>
                  <div className="mt-3">
                    <input
                      type="file"
                      onChange={(event) => {
                        const file = event.target.files[0];
                        if (file) {
                          console.log("Selected file:", file);
                          setFieldValue("proof_attachment", file);
                        }
                      }}
                      className=""
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="flex flex-wrap ">
              <div className="w-full md:w-1/2">
                <div className="m-3">
                  <div className="flex gap-1">
                    <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                      Proof Required.
                    </h3>
                    <div>
                      <span className=" font-bold text-[#ef4444]  text-red-500">
                        *
                      </span>
                    </div>
                  </div>
                  <div className="mt-3">
                    <input
                      placeholder="Transaction Remark."
                      onChange={handleChange("transaction_remark")}
                      onBlur={handleBlur("transaction_remark")}
                      value={values.transaction_remark}
                      className={`${
                        errors.transaction_remark
                          ? "w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                          : "w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                      }`}
                    />
                    {errors.transaction_remark && touched.transaction_remark ? (
                      <p className="text-[#ef4444]">
                        {errors.transaction_remark}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="w-full md:w-1/2">
                <div className="m-3">
                  <div className="flex gap-1">
                    <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                      Proof Attachment.
                    </h3>
                    <div>
                      <span className=" font-bold text-[#ef4444]  text-red-500">
                        *
                      </span>
                    </div>
                  </div>
                  <div className="mt-3">
                    <input type="file" className="" />
                  </div>
                </div>
              </div>
            </div> */}

            {/* {formState?.reciever_Bank && formState?.switch_Bank ? (
              <div className="flex items-center  justify-end gap-4 mt-8">
                <button
                  type="submit"
                  onClick={handleSubmit}
                  className="bg-gradient-to-r from-[#3C76D5] px-12 to-[#0AB9FC] py-2 rounded-full text-[#fff]"
                >
                  Submit
                </button>
              </div>
            ) : (
              ""
            )} */}


            {viewComplaint?.payment_gateway_status?.merchant ? (
             ""
            ) : (
              <div className="flex items-center  justify-end gap-4 mt-8">
              <button
                type="submit"
                onClick={handleSubmit}
                className="bg-gradient-to-r from-[#3C76D5] px-12 to-[#0AB9FC] py-2 rounded-full text-[#fff]"
              >
                Submit
              </button>
            </div>
            )}

            {/* new field */}
          </div>
        )}
      </Formik>
    </div>
  );
};

export default PaymentGateway;
