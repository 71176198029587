import React, { useState, useEffect, useRef } from "react";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { LuUser2 } from "react-icons/lu";
import { useAuth } from "../context/AuthContext";
import { PiSignOut } from "react-icons/pi";


const Dropdown = () => {
  const authToken = JSON.parse(localStorage.getItem("authToken"));

  const token = authToken;
  const navigate = useNavigate();
  const { setIsAuthenticated } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const logout = () => {
    const token = localStorage.removeItem("authToken");
    if (!token) {
      navigate("/login");
    }
    setIsAuthenticated(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      <div>
        <button
          type="button"
          onClick={toggleDropdown}
          className="inline-flex items-center justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none capitalize"
        >
          {/* {token?.user?.bank_name ? token?.user?.bank_name : token?.user?.firstname : token.user.gateway_name} */}
          {token?.user?.bank_name || token?.user?.firstname || token?.user?.gateway_name || token?.user?.merchant_name}
          <div>
            {isOpen ? (
              <MdOutlineKeyboardArrowUp size={20} />
            ) : (
              <MdOutlineKeyboardArrowDown size={20} />
            )}
          </div>
        </button>
      </div>

      {isOpen && (
        <div
          className="z-[100] bg-[#fff] origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex={-1}
        >
          <div className="py-1" role="none">
            <Link to={'/profile'}>
            <div className="flex items-center text-gray-700  px-4 py-2 text-sm hover:bg-gray-100 gap-2 cursor-pointer">
              <div>
                <LuUser2 />
              </div>
              <div>
                <p>Profile</p>
              </div>
            </div>
            </Link>

            <div
              className="flex items-center text-gray-700  px-4 py-2 text-sm hover:bg-gray-100 gap-2 cursor-pointer"
              tabIndex={-1}
              onClick={logout}
            >
              <div>
              <PiSignOut />
              </div>
              <div>
                <p> Sign out</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
