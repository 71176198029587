import React from 'react'
import CardPrototype from './Cardprototype'


export default function DashboardCard({ icon, title, description }) {
  return (

    <CardPrototype>
      <div className='flex items-center gap-3  '>
        <div className='flex justify-center items-center rounded-full  p-3 bg-gradient-to-r from-[#3C76D5] to-[#0AB9FC]'>
          {icon}
        </div>
        <div className='flex flex-col justify-center'>
          <div className='text-md font-semibold text-[#828385]'>
            {title}

          </div>
          <div className='text-[#2B3674] font-bold text-xl'>
            {description}
          </div>

        </div>

      </div>
    </CardPrototype>

  )
}
