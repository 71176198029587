import axios from "axios";
import { base } from "../config";

export const LoginUser = async (email, password) => {
  //   console.log(email);
  try {
    const { data } = await axios.post(`${base.API_URL}user/login/`, {
      email: email,
      password: password,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const VerifyEmail = async (email) => {
  //   console.log(email);
  try {
    const { data } = await axios.post(`${base.API_URL}user/forgot/password/`, {
      email: email,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getUserInfo = async (validToken) => {
  //   console.log(email);
  const config = {
    headers: {
      Authorization: `${validToken}`,
    },
  };
  try {
    const { data } = await axios.get(`${base.API_URL}user/info`, config);
    return data;
  } catch (error) {
    throw error;
  }
};


export const VerifyOTP = async (values) => {
  console.log("in controller",values)
  try {
    const { data } = await axios.post(`${base.API_URL}user/verify/otp`, {
      email: values?.email,
      otp: values?.otp
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const SetNewPassword = async (values, OTPToken) => {
  console.log("in controller values==",values, "in controller OTPToken==", OTPToken)
  // const config = {
  //   headers: {
  //     Authorization: `${OTPToken}`,
  //   },
  // };
  try {
    const { data } = await axios.post(`${base.API_URL}user/set/password/`, {
      token: OTPToken,
      newPassword : values,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const ChangeNewPasswordAPI = async (values, token) => {
  console.log("sadas", values);
  const config = {
    headers: {
      Authorization: `${token}`,
    },
  };
  try {
    const { data } = await axios.patch(
      `${base.API_URL}user/change/password`,
      values,
      config
    );
    return data;
  } catch (error) {
    throw error;
  }
};
