import axios from "axios";
import { base } from "../config";
// token.token, location?.state, values
export const AddNewComplaintApi = async (token, id, values) => {
  // console.log("token in controller==>>",token , "id==>>", "values==>>", values)
  const config = {
    headers: {
      Authorization: `${token}`,
    },
  };
  //   console.log(email);
  try {
    const { data } = await axios.post(
      `${base.API_URL}complaint/register/${id ? `?id=${id}` : ""}`,
      values,
      config
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const GetAllSuperAdminComplaints = async (token) => {
  // console.log("token in controller alladmin api", token);
  const config = {
    headers: {
      Authorization: `${token}`,
    },
  };
  //   console.log(email);
  try {
    const { data } = await axios.get(
      `${base.API_URL}complaint/all_fetch`,
      config
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const GetAllAdminComplaintsApi = async (token) => {
  const config = {
    headers: {
      Authorization: `${token}`,
    },
  };
  //   console.log(email);
  try {
    const { data } = await axios.get(`${base.API_URL}complaint/fetch`, config);
    return data;
  } catch (error) {
    throw error;
  }
};

export const GetPincodeApi = async (pincode) => {
  // const config = {
  //   headers: {
  //     Authorization: `${token}`,
  //   },
  // };
  //   console.log(email);
  try {
    const { data } = await axios.get(`https://api.postalpincode.in/pincode/${pincode}`);
    return data;
  } catch (error) {
    throw error;
  }
};



export const ViewSingleAdminComplaintsApi = async (id, token) => {
  const config = {
    headers: {
      Authorization: `${token}`,
    },
  };
  //   console.log(email);
  try {
    const { data } = await axios.get(
      `${base.API_URL}complaint/view/${id}`,
      config
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const AddRecieverAdminComplaintsApi = async (token, id, val) => {
  const config = {
    headers: {
      Authorization: `${token}`,
    },
  };
  //   console.log(email);
  try {
    const { data } = await axios.patch(
      `${base.API_URL}complaint/receiver_bank/${id}`,
      val,
      config
    );
    return data;
  } catch (error) {
    throw error;
  }
};
export const AddSwitchBankAdminComplaintsApi = async (token, id, val) => {
  const config = {
    headers: {
      Authorization: `${token}`,
    },
  };
  try {
    const { data } = await axios.patch(
      `${base.API_URL}complaint/switch_bank?id=${id}`,
      val,
      config
    );
    return data;
  } catch (error) {
    throw error;
  }
};
export const AddMerchantAdminComplaintsApi = async (token, id, val) => {
  const config = {
    headers: {
      Authorization: `${token}`,
    },
  };
  try {
    const { data } = await axios.patch(
      `${base.API_URL}complaint/merchant_status/${id}`,
      val,
      config
    );
    return data;
  } catch (error) {
    throw error;
  }
};
export const AddPaymentAdminComplaintsApi = async (token, id, val) => {
  const config = {
    headers: {
      Authorization: `${token}`,
    },
  };
  try {
    const { data } = await axios.patch(
      `${base.API_URL}complaint/payment_gateway/${id}`,
      val,
      config
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const AddSwitchAdminComplaintsApi = async (token, id, val) => {
  const config = {
    headers: {
      Authorization: `${token}`,
    },
  };
  //   console.log(email);
  try {
    const { data } = await axios.patch(
      `${base.API_URL}complaint/switch_bank/${id}`,
      val,
      config
    );
    return data;
  } catch (error) {
    throw error;
  }
};
