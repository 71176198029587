import moment from "moment/moment";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

const AllAdminComplaintsTable = ({ TableHeading, sampleData }) => {
  
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(8);

  useEffect(() => {
    setData(sampleData);
  }, [sampleData]);

  useEffect(() => {
    // This effect runs whenever searchTerm or currentPage changes
    // Filter data based on searchTerm
    // const filteredData = sampleData?.filter(
    //   (item) =>
    //     item.refererId.toString().includes(searchTerm.toLowerCase()) ||
    //     item.userId.toString().includes(searchTerm.toLowerCase()) ||
    //     item.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //     item.mobileNumber
    //       .toString()
    //       .toLowerCase()
    //       .includes(searchTerm.toLowerCase()) ||
    //     item.createdAt.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //     item.updatedAt.toLowerCase().includes(searchTerm.toLowerCase())
    // );
    // Calculate pagination
    // const startIndex = (currentPage - 1) * perPage;
    // const endIndex = startIndex + perPage;
    // const paginatedData = filteredData?.slice(startIndex, endIndex);
    // setData(paginatedData);
  }, [searchTerm, currentPage, perPage, sampleData]);

 
  return (
    <div className="w-full  border border-[#efefef] rounded-md mt-5 ">
      <div className="w-full text-center  overflow-x-auto">
        <table className="w-full border border-[#dfdfdf] shadow-xl table_scroll_bar">
          <thead>
            <tr className="">
              {TableHeading?.map((item, index) => (
                <th
                  key={index}
                  className="border whitespace-nowrap py-3 px-4 rounded-t-3xl"
                >
                  {item}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.length > 0 ? (
              data?.map((item, index) => (
                <tr
                  key={index}
                  className={`${
                    index % 2 === 0 ? "bg-[#fff] shadow-md" : "bg-[#eff1f7]"
                  } h-12`}
                >
                  <td className="border p-2">{index + 1}</td>

                  {/* <Link
                    to={`/admin/add-admin-complaint/${item._id}`}
                  > */}
                  <td
                    className="border p-2"
                  >
                    {item?.complaint_Details?.complaint_number}
                  </td>
                  {/* </Link> */}

                  {/* <td className="border p-2">{moment(item.createdAt).format("DD-MM-YYYY")}</td> */}
                  <td className="border p-2">
                    {moment(item?.complaint_Details?.complaint_date).format("DD-M-YYYY")}
                  </td>
                  <td className="border p-2">{item?.generated_by?.email}</td>
                  <td className="border p-2">
                    {item?.complaint_Details?.complaint_category}
                  </td>
                  <td className="border p-2">
                    {item?.complaint_Details?.complaint_sub_category}
                  </td>
                  <td className="border p-2">
                    {item?.consumer_Details?.first_name +
                      " " +
                      item?.consumer_Details?.last_name}
                  </td>
                  <td className="border p-2">
                    <button  onClick={() => {
                      navigate(`/admin/add-new-complaint`, {
                        state: item?._id,
                      });
                    }} className="bg-gradient-to-r from-[#3C76D5] to-[#0AB9FC] px-8 py-2 rounded-full text-[#fff]">View</button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="w-full text-center" colSpan="6">
                  No data Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Pagination */}

      {/* {data?.length > 0 && (
        <div className="mt-4">
          {sampleData?.length > perPage && (
            <div className="mt-4 flex justify-center items-center gap-5">
              <Stack spacing={2}>
                <Pagination
                  count={Math.ceil(sampleData.length / perPage)}
                  page={currentPage}
                  onChange={handlePageChange}
                  variant="outlined"
                  shape="rounded"
                  boundaryCount={2}
                  siblingCount={2}
                  hidePrevButton={currentPage === 1}
                  hideNextButton={
                    currentPage === Math.ceil(sampleData.length / perPage)
                  }
                />
              </Stack>
            </div>
          )}
        </div>
      )} */}
    </div>
  );
};

export default AllAdminComplaintsTable;
