import { Pagination, Stack } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Switch from "@mui/material/Switch";
import { ChangeOrgStatus } from "../../controller/organization";
import { toast } from "react-toastify";

const CyberTable = ({ TableHeading, sampleData }) => {
  // console.log("sss",sampleData)
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(10); // Set items per page
  const authToken = JSON.parse(localStorage.getItem("authToken"));
  const token = authToken.token;

  const ChangeStatus = async(id, status) => {
    const status1 = status ? "active" : "inactive";
  //   if (status) {
  //     let itemIndex = data?.findIndex((item) => item._id == id);
  //     console.log('sasaasee', itemIndex);
  //     let IndexObj = data[itemIndex];
  //     console.log("IndexObj===", IndexObj)
  //     IndexObj.status = 'active';
  //     let finalArray = [...data];
  //     finalArray[itemIndex] = IndexObj;
  //     setData(finalArray);
  // } else {
  //     let itemIndex = data?.findIndex((item) => item._id == id);
  //     let IndexObj = data[itemIndex];
  //     IndexObj.status = 'inactive';
  //     let finalArray = [...data];
  //     finalArray[itemIndex] = IndexObj;
  //     setData(finalArray);
  // }

   await ChangeOrgStatus(token, id, status1)
      .then((res) => {
        toast.success(res.message);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  useEffect(() => {
    setData(sampleData);
  }, [sampleData]);

  useEffect(() => {
    // This effect runs whenever searchTerm or currentPage changes
    const filteredData = sampleData?.filter((item) =>
      Object.values(item).some((val) =>
        String(val).toLowerCase().includes(searchTerm.toLowerCase())
      )
    );

    // Calculate pagination
    const startIndex = (currentPage - 1) * perPage;
    const endIndex = startIndex + perPage;
    const paginatedData = filteredData?.slice(startIndex, endIndex);

    setData(paginatedData);
  }, [searchTerm, currentPage, perPage]);

  // Pagination handler
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const label = { inputProps: { "aria-label": "Color switch demo" } };

  return (
    <div className="w-full border border-[#efefef] rounded-md mt-5">
      <input
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="mb-4 p-2 border rounded"
      />
      <div className="w-full text-center overflow-x-auto">
        <table className="w-full border border-[#dfdfdf] shadow-xl table_scroll_bar">
          <thead>
            <tr>
              {TableHeading?.map((item, index) => (
                <th
                  key={index}
                  className="border whitespace-nowrap py-3 px-4 rounded-t-3xl"
                >
                  {item}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.length > 0 ? (
              data?.map((item, index) => (
                <tr
                  key={index}
                  className={`${
                    index % 2 === 0 ? "bg-[#fff] shadow-md" : "bg-[#eff1f7]"
                  } h-12`}
                >
                  <td className="border p-2">{index + 1}</td>

                  <td
                    onClick={() => {
                      navigate(`/superadmin/add-new-user`, {
                        state: { id: item?._id, tabId: 1 },
                      });
                    }}
                    className="border p-2"
                  >
                    {item.cell_name}
                  </td>
                  {/* <td  onClick={() => {
                      navigate(`/superadmin/add-new-user`, {
                        state: {item?._id, tabId:1},
                      });
                    }} className="border p-2">{item.cell_name}</td> */}
                  <td className="border p-2">{item.cell_branch}</td>
                  <td className="border p-2">{item.cell_location}</td>
                  <td className="border p-2">{item.cell_code}</td>
                  <td className="border p-2">{item.email}</td>
                  <td className="border p-2">
                    <Switch
                      {...label}
                      defaultChecked={item.status ===  'active' ? true:false}
                      onChange={(e) => {
                        e.preventDefault();
                        ChangeStatus(item?._id, e.target.checked);
                      }}
                    />
                  </td>
                  <td className="border p-2">{item.type}</td>
                  {/* <td className="border p-2">
                    <button onClick={() => navigate(`/view/${item.type}`)}>
                      {item.type}
                    </button>
                  </td> */}
                </tr>
              ))
            ) : (
              <tr>
                <td className="w-full text-center" colSpan="8">
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      {data?.length > 0 && (
        <div className="mt-4">
          {sampleData?.length > perPage && (
            <div className="mt-4 flex justify-center items-center gap-5">
              <Stack spacing={2}>
                <Pagination
                  count={Math.ceil(
                    sampleData.filter((item) =>
                      Object.values(item).some((val) =>
                        String(val)
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      )
                    )?.length / perPage
                  )}
                  page={currentPage}
                  onChange={handlePageChange}
                  variant="outlined"
                  shape="rounded"
                  boundaryCount={2}
                  siblingCount={2}
                  hidePrevButton={currentPage === 1}
                  hideNextButton={
                    currentPage ===
                    Math.ceil(
                      sampleData?.filter((item) =>
                        Object.values(item).some((val) =>
                          String(val)
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        )
                      )?.length / perPage
                    )
                  }
                />
              </Stack>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CyberTable;
