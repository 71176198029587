// AuthContext.js
import React, { createContext, useState, useContext } from 'react';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
const token = localStorage.getItem('authToken');
  const login = () => setIsAuthenticated(token);
  const logout = () => setIsAuthenticated(false);

  return (
    <AuthContext.Provider value={{ setIsAuthenticated, isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
