import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { AddReciverBankDetailsValidations } from "../data/validations";
import { GetAllBanks } from "../controller/organization";
import ReactCustomSelect from "./ui/ReactCustomSelect";
import { AddRecieverAdminComplaintsApi } from "../controller/complaints";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const RecieverBankComp = ({
  recieverBankDetails,
  viewComplaint,
  id,
  formState,
  handleSubmit,
}) => {
  // console.log("formState===", formState);
  // console.log("viewComplaint", viewComplaint);
  const [allBanks, setAllBanks] = useState();
  const authToken = localStorage.getItem("authToken");
  const token = JSON.parse(authToken);
  const navigate = useNavigate();

  const customStyles = (hasError) => ({
    control: (provided, state) => ({
      ...provided,
      borderColor: hasError ? "red" : provided.borderColor,
      boxShadow: state.isFocused ? 0 : 0,
    }),
  });

  // useEffect(() => {
  //   setFormState({...setFormState, reciever_Bank:viewComplaint.receiver_bank_status?})
  // }, [])

  useEffect(() => {
    GetAllBanks(token.token)
      .then((res) => {
        setAllBanks(res.bank);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const bank = allBanks?.find(
    (item) =>
      item?.bankname === recieverBankDetails?.switch_bank
  );

  console.log("bankingggg----",bank)


  const handleADD = (val) => {
    console.log("value---of reciever bank---", val);
    AddRecieverAdminComplaintsApi(token.token, id, val)
      .then((res) => {
        // console.log("first----", res)
        toast.success(res.message);
        // // resetForm({});
        navigate("/admin/complaint-management");
      })
      .catch((error) => {
        console.log(error);
      });
    // handleSubmit(val);
  };
  return (
    <div>
      <Formik
        initialValues={{
          transaction_status:
            viewComplaint?.receiver_bank_status?.transaction_status,
          refund_status: viewComplaint?.receiver_bank_status?.refund_status,
          transaction_failed_reason:
            viewComplaint?.receiver_bank_status?.transaction_failed_reason,
          refund_reason: viewComplaint?.receiver_bank_status?.refund_reason,
          transaction_remark:
            viewComplaint?.receiver_bank_status?.transaction_remark,
          switch_bank: viewComplaint?.receiver_bank_status?.switch_bank,
          complaint_number: viewComplaint?.complaint_Details?.complaint_number,
        }}
        onSubmit={handleADD}
        validationSchema={AddReciverBankDetailsValidations}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          values,
          errors,
          touched,
        }) => (
          <div className=" rounded-xl p-4">
            <div className="pt-2">
              <div>
                <h3 className="text-primary font-poppins font-medium text-xl">
                  Reciever Bank Status
                </h3>
              </div>
            </div>

            <div className="flex flex-wrap ">
              <div className="w-full lg:w-1/2">
                <div className="p-2">
                  <div className="flex gap-1">
                    <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                      Transaction Status
                    </h3>
                    <div>
                      <span className=" font-bold text-[#ef4444]  text-red-500">
                        *
                      </span>
                    </div>
                  </div>

                  <div className="pt-3">
                    <select
                      onChange={(e) =>
                        setFieldValue("transaction_status", e.target.value)
                      }
                      defaultValue={
                        viewComplaint?.receiver_bank_status?.transaction_status
                      }
                      disabled={
                        viewComplaint?.receiver_bank_status?.transaction_status
                          ? true
                          : false
                      }
                      className={`${
                        errors.transaction_status
                          ? "w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                          : "w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                      }`}
                    >
                      <option className="text-lg">
                        --Select Transaction Status --
                      </option>
                      <option value={"pending"} className="text-lg">
                        Pending
                      </option>
                      <option value={"success"} className="text-lg">
                        Success
                      </option>
                      <option value={"reject"} className="text-lg">
                        Reject
                      </option>
                    </select>
                    {errors.transaction_status && touched.transaction_status ? (
                      <p className="text-[#ef4444]">
                        {errors.transaction_status}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="w-full lg:w-1/2">
                <div className="p-2">
                  <div className="flex gap-1">
                    <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                      Refund Status
                    </h3>
                    <div>
                      <span className=" font-bold text-[#ef4444]  text-red-500">
                        *
                      </span>
                    </div>
                  </div>
                  <div className="pt-3">
                    <select
                      onChange={(e) =>
                        setFieldValue("refund_status", e.target.value)
                      }
                      defaultValue={
                        viewComplaint?.receiver_bank_status?.refund_status
                      }
                      disabled={
                        viewComplaint?.receiver_bank_status?.refund_status
                          ? true
                          : false
                      }
                      className={`${
                        errors.refund_status
                          ? "w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                          : "w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                      }`}
                    >
                      <option className="text-lg">
                        --Select Refund Status --
                      </option>
                      <option value={"yes"} className="text-lg">
                        Yes
                      </option>
                      <option value={"no"} className="text-lg">
                        No
                      </option>
                    </select>
                    {errors.refund_status && touched.refund_status ? (
                      <p className="text-[#ef4444]">{errors.refund_status}</p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap">
              {values?.transaction_status != "success" && (
              <div className="w-full md:w-1/2">
                <div className="m-3">
                  <div className="flex gap-1">
                    <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                      Transaction Failed Reason.
                    </h3>
                    <div>
                      <span className=" font-bold text-[#ef4444]  text-red-500">
                        *
                      </span>
                    </div>
                  </div>
                  <div className="mt-3">
                    <input
                      placeholder="Transaction Failed Reason."
                      onChange={handleChange("transaction_failed_reason")}
                      onBlur={handleBlur("transaction_failed_reason")}
                      value={values.transaction_failed_reason}
                      className={`${
                        errors.transaction_failed_reason
                          ? "w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                          : "w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                      }`}
                      // disabled={
                      //   recieverBankDetails?.transaction_failed_reason
                      //     ?.transaction_failed_reason
                      //     ? true
                      //     : false
                      // }
                    />
                    {errors?.transaction_failed_reason &&
                    touched?.transaction_failed_reason ? (
                      <p className="text-[#ef4444]">
                        {errors?.transaction_failed_reason}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
              )}

              <div className="w-full md:w-1/2">
                <div className="m-3">
                  <div className="flex gap-1">
                    <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                      Refund Reason.
                    </h3>
                    <div>
                      <span className=" font-bold text-[#ef4444]  text-red-500">
                        *
                      </span>
                    </div>
                  </div>
                  <div className="mt-3">
                    <input
                      placeholder="Refund Reason."
                      onChange={handleChange("refund_reason")}
                      onBlur={handleBlur("refund_reason")}
                      value={values.refund_reason}
                      className={`${
                        errors.refund_reason
                          ? "w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                          : "w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                      }`}
                      // disabled={
                      //   recieverBankDetails?.refund_reason?.refund_reason
                      //     ? true
                      //     : false
                      // }
                    />
                    {errors.refund_reason && touched.refund_reason ? (
                      <p className="text-[#ef4444]">{errors.refund_reason}</p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap ">
              <div className="w-full md:w-1/2">
                <div className="m-3">
                  <div className="flex gap-1">
                    <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                      Transaction Remark.
                    </h3>
                    <div>
                      <span className=" font-bold text-[#ef4444]  text-red-500">
                        *
                      </span>
                    </div>
                  </div>
                  <div className="mt-3">
                    <input
                      placeholder="Transaction Remark."
                      onChange={handleChange("transaction_remark")}
                      onBlur={handleBlur("transaction_remark")}
                      value={values.transaction_remark}
                      className={`${
                        errors.transaction_remark
                          ? "w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                          : "w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                      }`}
                    />
                    {errors.transaction_remark && touched.transaction_remark ? (
                      <p className="text-[#ef4444]">
                        {errors.transaction_remark}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="w-full md:w-1/2">
                <div className="m-3">
                  <div className="flex gap-1">
                    <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                      Switch Bank.
                    </h3>
                    <div>
                      <span className=" font-bold text-[#ef4444]  text-red-500">
                        *
                      </span>
                    </div>
                  </div>
                  <div className="mt-3">
                    <ReactCustomSelect
                      styles={customStyles(errors?.section?.message)}
                      options={allBanks}
                      defaultValue={bank}
                      multiSelect={true}
                      onchange={(item) =>
                        setFieldValue("switch_bank", item.bankname)
                      }
                    />

                    {errors.switch_bank && touched.switch_bank ? (
                      <p className="text-[#ef4444]">{errors.switch_bank}</p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            {formState?.reciever_Bank && formState?.switch_Bank ? (
              ""
            ) : (
              <div className="flex items-center  justify-end gap-4 mt-8">
                <button
                  type="submit"
                  onClick={handleSubmit}
                  className="bg-gradient-to-r from-[#3C76D5] px-12 to-[#0AB9FC] py-2 rounded-full text-[#fff]"
                >
                  Submit
                </button>
              </div>
            )}

            {/* new field */}
          </div>
        )}
      </Formik>
    </div>
  );
};

export default RecieverBankComp;
