import React, { useState } from "react";
import {
  IoMdCheckmarkCircleOutline,
  IoMdNotificationsOutline,
} from "react-icons/io";

import { FiInfo } from "react-icons/fi";
import { CiSearch } from "react-icons/ci";
import CardPrototype from "../../components/Cardprototype";
import DashboardCard from "../../components/DashboardCard";
import Chart from "../../components/Charts";
import Table from "../../components/TableAgent";
import Dropdown from "../../components/Dropdown";
import DashboardHeader from "../../components/DashboardHeader";

function AdminDashboard() {
  const [searchTerm, setSearchTerm] = useState("");

  const TableHeading = [
    "S.No.",
    "Complaint Number",
    "Complaint Date",
    "Complaint Generated By",
    "Complaint Category",
    "Complaint Sub Category",
    "Consumer Name",
    "Transaction Date & Time",
    "Transaction Status",
    "UTR/RRN Number",
    "Merchant Name",
    "Merchant VPA",
    "Payment Screenshot",
    "Complaint Pending at",
    "View Full Detail",
    "Action",
  ];
  return (
    <div className="bg-[#FAFBFF] overflow-x-hidden">
      {/* Dashboard header start */}
      <DashboardHeader title={"Pages / Dashboard"} subtitle={"Main Dashboard"} />
      {/* Dashboard header end */}

      <div className="pt-2 flex flex-wrap w-full">
        <div className="w-1/3">
          <div className="m-2 ">
            <DashboardCard
              icon={
                <IoMdCheckmarkCircleOutline className="text-[#fff]  font-bold text-3xl" />
              }
              title="Total Complaints"
              description="34"
            />
          </div>
        </div>

        <div className="w-1/3">
          <div className="m-2">
            <DashboardCard
              icon={
                <IoMdCheckmarkCircleOutline className="text-[#fff] font-bold text-3xl" />
              }
              title="Pending Complaints"
              description="5"
            />
          </div>
        </div>

        <div className="w-1/3">
          <div className="m-2">
            <DashboardCard
              icon={
                <IoMdCheckmarkCircleOutline className="text-[#fff] font-bold text-3xl" />
              }
              title="Approved Complaints"
              description="3"
            />
          </div>
        </div>

        <div className="w-1/3">
          <div className="m-2">
            <DashboardCard
              icon={
                <IoMdCheckmarkCircleOutline className="text-[#fff] font-bold text-3xl" />
              }
              title="Rejected Complaints"
              description="1"
            />
          </div>
        </div>

        <div className="w-1/3">
          <div className="m-2">
            <DashboardCard
              icon={
                <IoMdCheckmarkCircleOutline className="text-[#fff] font-bold text-3xl" />
              }
              title="Open Complaints"
              description="10"
            />
          </div>
        </div>

        <div className="w-1/3">
          <div className="m-2">
            <DashboardCard
              icon={
                <IoMdCheckmarkCircleOutline className="text-[#fff] font-bold text-3xl" />
              }
              title="Resolved Complaints"
              description="15"
            />
          </div>
        </div>
      </div>

      {/* chart start */}
      <div className="flex  flex-wrap  w-full my-5 mx-2">
        {/* Bar chart */}
        <div className="w-full lg:w-1/2">
          <div className="m-1">
            <CardPrototype>
              <>
                <div className="text-primary text-xl font-semibold">
                  Complaint By Department
                </div>
                <Chart
                  option={{
                    series: [
                      {
                        name: "Complaint",
                        data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
                      },
                      {
                        name: "Open Complaint",
                        data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
                      },
                      {
                        name: "Resolved",
                        data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
                      },
                    ],
                    plotOptions: {
                      bar: {
                        horizontal: false,
                        columnWidth: "55%",
                      },
                    },
                    colors: ["#008FFB", "#00E396", "#FEB019"],
                    dataLabels: {
                      enabled: false,
                    },
                    stroke: {
                      curve: "smooth",
                    },
                    xaxis: {
                      categories: [
                        "Feb",
                        "Mar",
                        "Apr",
                        "May",
                        "Jun",
                        "Jul",
                        "Aug",
                        "Sep",
                        "Oct",
                      ],
                    },
                    tooltip: {},
                  }}
                  type="bar"
                  height={400}
                />
              </>
            </CardPrototype>
          </div>
        </div>
        <div className="w-full md:w-1/2">
          <div className="m-1">
            <CardPrototype>
              <>
                <div className="text-text-primary text-xl font-semibold mb-4">
                  Complaint Assign By
                </div>
                <Chart
                  option={{
                    series: [25, 15, 44, 55, 41, 17],
                    plotOptions: {
                      bar: {
                        horizontal: false,
                        columnWidth: "55%",
                      },
                      pie: {
                        dataLabels: {
                          offset: -5,
                        },
                      },
                    },
                    labels: [
                      "Monday",
                      "Tuesday",
                      "Wednesday",
                      "Thursday",
                      "Friday",
                      "Saturday",
                    ],
                    colors: ["#008FFB", "#00E396", "#FEB019"],
                    theme: {
                      monochrome: {
                        enabled: true,
                      },
                    },
                    legend: {
                      show: false,
                    },
                  }}
                  type="pie"
                  height={400}
                />
              </>
            </CardPrototype>
          </div>
        </div>
        <div className="w-full md:w-1/2">
          <CardPrototype>
            <>
              <div className="text-text-primary text-xl font-semibold">
                Track Complaints
              </div>
              <Chart
                option={{
                  series: [
                    {
                      data: [
                        400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380,
                      ],
                    },
                  ],
                  plotOptions: {
                    bar: {
                      borderRadius: 4,
                      borderRadiusApplication: "end",
                      horizontal: true,
                    },
                  },
                  xaxis: {
                    categories: [
                      "South Korea",
                      "Canada",
                      "United Kingdom",
                      "Netherlands",
                      "Italy",
                      "France",
                      "Japan",
                      "United States",
                      "China",
                      "Germany",
                    ],
                  },
                }}
                type="bar"
                height={400}
              />
            </>
          </CardPrototype>
        </div>
      </div>

      <div>
        <div className="flex justify-between items-center w-full px-4">
          <div>
            <h4 className="text-primary font-bold text-xl">
              Recent Complaints
            </h4>
          </div>
          <div>
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search..."
              className="border border-[#e2dfdf] rounded-md p-2 mb-4 outline-none"
            />
          </div>
        </div>
        <Table TableHeading={TableHeading} />
      </div>
    </div>
  );
}

export default AdminDashboard;
