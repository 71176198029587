import { useEffect, useState } from "react";
import Select from "react-select";

const ReactCustomSelect = ({
  options,
  defaultValue,
  multiSelect = false,
  label,
  onchange,
  error,
  styles,
//   resetTrigger = { resetTrigger },
}) => {
  console.log("defaultValue-----",defaultValue)
  const [selectedValue, setSelectedValue] = useState(defaultValue ? defaultValue:null);
  console.log("selectedValue-----",selectedValue)

//   useEffect(() => {
//     setSelectedValue(null);
//   }, [resetTrigger]);

  const handleChange = (selectedOption) => {
    setSelectedValue(selectedOption);
    onchange(selectedOption);
  };
  return (
    <>
      {/* <label className="capitalize tracking-wide text-gray-1 text-xs font-bold mb-2">
        {label} <span className="text-red-1 text-sm	">*</span>
      </label> */}
      <Select
        value={selectedValue}
        onChange={handleChange}
        styles={styles}
        placeholder="Select"
        options={options}
        getOptionLabel={(options) => {
          return options["bankname"];
        }}
        getOptionValue={(options) => {
          return options["bankname"];
        }}
        // isMulti={multiSelect}
        // onChange={onchange}
      />
      {error ? (
        <div>
          <span className="text-red-1 text-xs">{error} *</span>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default ReactCustomSelect;