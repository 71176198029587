import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { AddCyberCellValidations } from "../../data/validations";
import {
  AddCyberCell,
  GetSingleOrganization,
} from "../../controller/organization";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function CyberCell({ id }) {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [loader, setLoader] = useState(false);
  const authToken = JSON.parse(localStorage.getItem("authToken"));
  const token = authToken.token;

  useEffect(() => {
    if (id) {
      setLoader(true);
      GetSingleOrganization(token, id)
        .then((res) => {
          setLoader(true);
          setData(res?.result);
          setLoader(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  
  const AddCyberCellDetails = (values, { resetForm }) => {
    AddCyberCell(id, values, token)
      .then((res) => {
        toast.success(res.message);
        resetForm({});
        navigate("/admin/user-management");
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  return (
    <div>
      <div className="mt-4  px-4">
        {loader ? (
          <p>Loading.....</p>
        ) : (
          <Formik
            initialValues={{
              cell_name: data?.cell_name ? data?.cell_name : "",
              cell_branch: data?.cell_branch ? data?.cell_branch : "",
              cell_location: data?.cell_location ? data?.cell_location : "",
              cell_code: data?.cell_code ? data?.cell_code : "",
              email: data?.email ? data?.email : "",
              type: data?.type ? data?.type : "",
            }}
            onSubmit={AddCyberCellDetails}
            validationSchema={AddCyberCellValidations}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              values,
              errors,
              touched,
            }) => (
              <div className="border border-[#dfdfdf] rounded-xl p-4">
                <div>
                  <div className="flex flex-wrap">
                    <div className="w-full md:w-1/2">
                      <div className="m-3">
                        <div>
                          <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                            Cyber Cell Name
                          </h3>
                        </div>
                        <div className="mt-3">
                          <input
                            placeholder="Cyber Cell Name"
                            type="text"
                            name="cell_name"
                            onChange={handleChange("cell_name")}
                            onBlur={handleBlur("cell_name")}
                            value={values.cell_name}
                            className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                          />
                          {errors.cell_name && touched.cell_name ? (
                            <p className="text-[#ef4444]">{errors.cell_name}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="w-full md:w-1/2">
                      <div className="m-3">
                        <div>
                          <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                            Type of Branch
                          </h3>
                        </div>
                        <div className="mt-3">
                          <div>
                            <select
                              defaultValue={values?.type}
                              onChange={(e) =>
                                setFieldValue("type", e.target.value)
                              }
                              className="w-full border border-[#dfdfdf] focus:border-[#dfdfdf] focus:outline-none p-[0.35rem] cursor-pointer rounded-sm"
                            >
                              <option>--Select Branch--</option>
                              {authToken.user.role == "SUPER_ADMIN" && (
                                <option value={"admin"} className="text-lg">
                                  Admin
                                </option>
                              )}
                              <option value={"branch"} className="text-lg">
                                Branch
                              </option>
                            </select>

                            {errors.type && touched.type ? (
                              <p className="text-[#ef4444]">{errors.type}</p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-wrap ">
                    <div className="w-full md:w-1/2">
                      <div className="m-3">
                        <div>
                          <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                            Cyber Cell Location
                          </h3>
                        </div>
                        <div className="mt-3">
                          <input
                            placeholder="Jaipur"
                            type="text"
                            name="cell_location"
                            onChange={handleChange("cell_location")}
                            onBlur={handleBlur("cell_location")}
                            value={values.cell_location}
                            className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                          />
                          {errors.cell_location && touched.cell_location ? (
                            <p className="text-[#ef4444]">
                              {errors.cell_location}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="w-full md:w-1/2">
                      <div className="m-3">
                        <div>
                          <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                            Cyber Cell Code
                          </h3>
                        </div>
                        <div className="mt-3">
                          <input
                            placeholder="CA1234"
                            name="cell_code"
                            onChange={handleChange("cell_code")}
                            onBlur={handleBlur("cell_code")}
                            value={values.cell_code}
                            className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                          />
                          {errors.cell_code && touched.cell_code ? (
                            <p className="text-[#ef4444]">{errors.cell_code}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-wrap ">
                    <div className="w-full md:w-1/2">
                      <div className="m-3">
                        <div>
                          <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                            Email
                          </h3>
                        </div>
                        <div className="mt-3">
                          <input
                            placeholder="bobbank@gmail.com"
                            name="managerName"
                            type="email"
                            onChange={handleChange("email")}
                            onBlur={handleBlur("email")}
                            value={values.email}
                            className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                          />

                          <div>
                            {errors.email && touched.email ? (
                              <p className="text-[#ef4444]">{errors.email}</p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>

                    

                    <div className="w-full md:w-1/2">
                      <div className="m-3">
                        <div>
                          <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                            Cyber Cell Branch
                          </h3>
                        </div>
                        <div className="mt-3">
                          <input
                            placeholder="Default"
                            type="text"
                            name="cell_branch"
                            onChange={handleChange("cell_branch")}
                            onBlur={handleBlur("cell_branch")}
                            value={values.cell_branch}
                            className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                          />
                          {errors.cell_branch && touched.cell_branch ? (
                            <p className="text-[#ef4444]">
                              {errors.cell_branch}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center  justify-end gap-4 mt-8">
                    <button
                      type="submit"
                      onClick={handleSubmit}
                      className="bg-gradient-to-r from-[#3C76D5] px-12 to-[#0AB9FC] py-2 rounded-full text-[#fff]"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
}

export default CyberCell;
