import React from "react";
import {
  AdminSideBarMenuItems,
  sideBarMenuItems,
  superAdminSideBarMenuItems,
  userSideBarMenuItems,
} from "../data/menulinks";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IoMdLock } from "react-icons/io";
import { useAuth } from "../context/AuthContext";

const Sidebar = () => {
  const currentUrl = useLocation();
  const actualPath = currentUrl.pathname;
  const navigate = useNavigate();
  const localStorageData = JSON.parse(localStorage.getItem("authToken"));
  // console.log("localstorage Data==>>>>>",localStorageData)
  const sidebarlink =
    localStorageData?.user?.type == "admin" ||
    localStorageData?.user?.type == "branch"
      ? AdminSideBarMenuItems
      : superAdminSideBarMenuItems;
  const { setIsAuthenticated } = useAuth();
  const logout = () => {
    const token = localStorage.removeItem("authToken");
    if (!token) {
      navigate("/login");
    }
    setIsAuthenticated(false);
  };
  return (
    <div className="overflow-hidden">
      <div className="bg-white shadow-md  overflow-hidden p-8">
        <div className="bg-[#ffff]">
          <Link to={"/dashboard"}>
          <div>
            <img src="/securafin.png" alt="" className="object-contain h-12 w-52"/>
          </div>
          </Link>
        </div>

        <div>
          {sidebarlink.map((item, index) => (
            <div className="group" key={index}>
              
              <div
                className={
                  actualPath === item.href
                    ? "flex items-center gap-3 py-3 cursor-pointer text-primary border-r-4 "
                    : "flex items-center gap-3 py-3 cursor-pointer group-hover:text-primary"
                }
              >
                <div
                  className={
                    actualPath === item.href
                      ? "flex items-center gap-3 py-4 cursor-pointer text-primary"
                      : "flex items-center gap-3 py-4 cursor-pointer group-hover:text-primary"
                  }
                >
                  {item.icon}
                </div>
                <Link to={item.href}>
                  <div
                    className={
                      actualPath === item.href
                        ? "text-primary font-bold"
                        : "text-[#828385] group-hover:text-primary font-bold"
                    }
                  >
                    <h4>{item.name}</h4>
                  </div>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
