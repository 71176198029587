import React from "react";
import { BsFillGrid1X2Fill } from "react-icons/bs";
import { FaHome } from "react-icons/fa";
import { MdOutlineShoppingCart } from "react-icons/md";
 
export const superAdminSideBarMenuItems = [
  {
    name: "Dashboard",
    href: "/dashboard",
    icon: <FaHome className="text-[#A3AED0] font-bold group-hover:text-primary" />,
    hover: false,
  },
  {
    name: "Complaint Management",
    href: "/superadmin/complaint-management",
    icon: <MdOutlineShoppingCart className="text-[#A3AED0] font-bold group-hover:text-primary" />,
    hover: false,
  },
  {
    name: "ORG Management",
    href: "/superadmin/org-management",
    icon: <BsFillGrid1X2Fill className="text-[#A3AED0] font-bold group-hover:text-primary" />,
    hover: true,
  },
];


export const AdminSideBarMenuItems = [
  {
    name: "Dashboard",
    href: "/dashboard",
    icon: <FaHome className="text-[#A3AED0] font-bold group-hover:text-primary" />,
    hover: false,
  },
  {
    name: "Complaint Management",
    href: "/admin/complaint-management",
    icon: <MdOutlineShoppingCart className="text-[#A3AED0] font-bold group-hover:text-primary" />,
    hover: false,
  },
   {
    name: " User Management",
    href: "/admin/user-management",
    icon: <BsFillGrid1X2Fill className="text-[#A3AED0] font-bold group-hover:text-primary" />,
    hover: true,
  },
];
