import { Formik } from "formik";
import React, { useState } from "react";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { MdOutlineMailOutline } from "react-icons/md";
import { LoginValidations } from "../data/validations";
import { LoginUser } from "../controller/auth";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

function Login() {
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate(); // Initialize the navigate function
  const { setIsAuthenticated } = useAuth();
  const [loader, setLoader] = useState(false);

  const handleLogin = async (values, { setFieldError }) => {
    setLoader(true);
    LoginUser(values.email, values.password)
      .then((res) => {
        console.log("response in login api===>>>>>", res);
        setIsAuthenticated(true);
        toast.success("Logged In Successfully");
        // console.log("res",res)
        setLoader(false);
        // localStorage.setItem("authToken",res)
        const authData = {
          token: res.token,
          user: res.user,
        };
        localStorage.setItem("authToken", JSON.stringify(authData));

        if (res.user.changePassword == true) {
          navigate("/dashboard");
        } else {
          navigate("/change-old-password");
        }
      })
      .catch((error) => {
        setLoader(false);
        toast.error(error?.response?.data?.message);
      });
  };
  return (
    <div className="flex h-screen ">
      <div className="flex flex-wrap px-12 items-center">
        <div className="w-full md:w-1/2">
          <img src="/login_e.png" alt="" />
        </div>
        <div className="w-full md:w-1/2">
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            onSubmit={handleLogin}
            validationSchema={LoginValidations}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              values,
              errors,
              touched,
            }) => (
              <div className="bg-[#F4F9FF] rounded-2xl h-full">
                <div className="p-16">
                  <div className="flex justify-center">
                    <img
                      src="/securafin.png"
                      alt=""
                      className="object-contain h-12 w-52"
                    />
                  </div>

                  <h3 className="text-[#000] font-poppins font-semibold text-2xl text-center pt-3">
                    Welcome
                  </h3>
                  <h5 className="text-[#000] font-poppins font-medium text-lg pt-2 text-center">
                    Sign in To Continue
                  </h5>

                  <div className="flex justify-center">
                    <div className="relative w-[50%]">
                      <div className="flex justify-center pt-6">
                        <input
                          placeholder="Email"
                          name="email"
                          onChange={handleChange("email")}
                          onBlur={handleBlur("email")}
                          value={values.email}
                          className="border px-2 h-9 rounded-md border-[#8098F9] w-full"
                        />
                      </div>
                      <div className="absolute top-8 right-4">
                        <MdOutlineMailOutline
                          size={20}
                          className="text-[#C4C4C4]"
                        />
                      </div>
                      <div>
                        {errors.email && touched.email ? (
                          <p className="text-[#ef4444] text-sm text-end">
                            {errors.email}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <div className="relative w-[50%]">
                      <div className="flex justify-center pt-6">
                        <input
                          placeholder="Password"
                          name="password"
                          type={visible ? "text" : "password"}
                          value={values.password}
                          onBlur={handleBlur("email")}
                          onChange={handleChange("password")}
                          onKeyDown={(e) => {
                            if (e.key == "Enter") {
                              e.preventDefault();
                              handleSubmit();
                            }
                          }}
                          className="border px-2 h-9 rounded-md border-[#8098F9] w-full"
                        />
                      </div>
                      <div className="absolute top-8 right-4">
                        {visible ? (
                          <IoEyeOutline
                            size={20}
                            onClick={() => setVisible((prev) => !prev)}
                            className="text-[#C4C4C4] cursor-pointer"
                          />
                        ) : (
                          <IoEyeOffOutline
                            size={20}
                            onClick={() => setVisible((prev) => !prev)}
                            className="text-[#C4C4C4] cursor-pointer"
                          />
                        )}
                      </div>

                      <div>
                        {errors.password && touched.password ? (
                          <p className="text-[#ef4444] text-sm text-end">
                            {errors.password}
                          </p>
                        ) : null}
                      </div>

                      <div className="flex justify-end pt-2 ">
                        <Link to={"/forgot-password"}>
                          <p className="text-primary cursor-pointer">
                            Forgot Password?
                          </p>
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-center items-center pt-12 w-full ">
                    {loader ? (
                      <>
                        <button className="bg-[#3F7AFE] py-2 rounded-md text-[#fff] w-[47%]">
                          Loading....
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          onClick={handleSubmit}
                          className="bg-[#3F7AFE]  py-2 rounded-md text-[#fff] w-[47%]"
                        >
                          Login
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default Login;
