import { Formik } from "formik";
import React, { useState } from "react";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { MdOutlineMailOutline } from "react-icons/md";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { LoginUser, VerifyOTP } from "../../controller/auth";
import { useAuth } from "../../context/AuthContext";
import {
  ForgotPasswordValidations,
  LoginValidations,
  VerifyOtpValidations,
} from "../../data/validations";

function OTPScreen() {
  const navigate = useNavigate(); // Initialize the navigate function
  const { setIsAuthenticated } = useAuth();
  const [loader, setLoader] = useState(false);
  const location = useLocation()
  console.log("email in verify",location?.state)
  

  const handlePasswordForgot = async (values, { setFieldError }) => {
    console.log("value--->>>", values);
    const OTPInt = parseInt(values?.otp)
    console.log(OTPInt)
    setLoader(true);
    VerifyOTP(values)
      .then((res) => {
        console.log("response in login api===>>>>>", res);
        setIsAuthenticated(true);
        localStorage.setItem('OTPToken', res?.token)
        toast.success(res?.message);
        setLoader(false);
        navigate('/new-password')

        // const authData = {
        //   token: res.token,
        //   user: res.user,
        // };
        // localStorage.setItem("authToken", JSON.stringify(authData));

        // if (res.user.changePassword == true) {
        //   navigate("/dashboard");
        // } else {
        //   navigate("/change-old-password");
        // }
      })
      .catch((error) => {
        setLoader(false);
        toast.error(error?.response?.data?.message);
      });
  };
  return (
    <div className="flex h-screen ">
      <div className="flex flex-wrap px-12 items-center">
        <div className="w-full md:w-1/2">
          <img src="/login_e.png" alt="" />
        </div>
        <div className="w-full md:w-1/2">
          <Formik
            initialValues={{
              email: location?.state,
              otp: "",
            }}
            onSubmit={handlePasswordForgot}
            validationSchema={VerifyOtpValidations}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              values,
              errors,
              touched,
            }) => (
              <div className="bg-[#F4F9FF] rounded-2xl h-full">
                <div className="p-16">
                  <div className="flex justify-center">
                    <img
                      src="/securafin.png"
                      alt=""
                      className="object-contain h-12 w-52"
                    />
                  </div>

                  <h3 className="text-[#000] font-poppins font-semibold text-2xl text-center pt-6">
                    OTP Verification
                  </h3>
                   

                  <div className="flex justify-center">
                    <div className="relative w-[50%]">
                      <div className="flex justify-center pt-6">
                        <div>
                          <div className="pb-1">
                            <label className="font-poppins text-md">Enter OTP</label>
                          </div>
                          <div>
                            <input
                              placeholder="OTP"
                              name="otp"
                              type="number"
                              onChange={handleChange("otp")}
                              onBlur={handleBlur("otp")}
                              value={values.otp}
                              className="border px-2 h-9 rounded-md border-[#8098F9] w-full"
                            />
                          </div>
                        </div>
                      </div>
                      {/* <div className="absolute top-16 right-6">
                        <MdOutlineMailOutline
                          size={20}
                          className="text-[#C4C4C4]"
                        />
                      </div> */}
                      <div>
                        {errors.otp && touched.otp ? (
                          <p className="text-[#ef4444] text-sm text-end">
                            {errors.otp}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-center items-center pt-12 w-full ">
                    {loader ? (
                      <>
                        <button className="bg-[#3F7AFE] py-2 rounded-md text-[#fff] w-[47%]">
                          Loading....
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          type="submit"
                          onClick={handleSubmit}
                          className="bg-[#3F7AFE]  py-2 rounded-md text-[#fff] w-[47%]"
                        >
                          Submit
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default OTPScreen;
