import React, { useEffect, useState } from "react";
import Bank from "../components/organizations/Bank";
import DashboardHeader from "../components/DashboardHeader";
import PaymentGateway from "../components/PaymentGateway";
import CyberCell from "../components/organizations/CyberCell";
import { getUserInfo } from "../controller/auth";

const Profile = () => {
  const token = localStorage.getItem("authToken");
  const authToken = JSON.parse(token);
  const validToken = authToken?.token;
  const [userInfo, setUserInfo] = useState()
  // console.log("userInfo--->>>", userInfo);
  const id = authToken.user._id;
  // console.log("id--->>>", id);

  useEffect(() => {
    getUserInfo(validToken)
      .then((res) => {
        // console.log("res==-->>", res);
        setUserInfo(res.user)
        // setAllBanks(res.bank);
        // setLoader(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      <div>
        <DashboardHeader />
      </div>

      {/* {authToken.user.role === "BANK" ? (
        <Bank id={id} />
      ) : authToken.user.role === "PAYMENT" ? (
        <PaymentGateway id={id} />
      ) : authToken.user.role === "CYBERCELL" ? (
        <CyberCell id={id} />
      ) : null} */}

      {/*  */}

      <div className="border border-[#dfdfdf] rounded-xl p-4">
        <div>
          <div className="flex flex-wrap">
          {userInfo?.merchant_name && (
            <div className="w-full md:w-1/2">
              <div className="m-3">
                <div>
                  <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                    Merchant Name
                  </h3>
                </div>
                <div className="mt-3">
                  <div>
                    <input
                      placeholder="Bob Bank"
                      type="bank_name"
                      name="bank_name"
                      value={userInfo?.merchant_name}
                      disabled={userInfo?.merchant_name ? true : false}
                      className="w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                    />
                  </div>
                </div>
              </div>
            </div>
            )}
            {userInfo?.bank_name && (
            <div className="w-full md:w-1/2">
              <div className="m-3">
                <div>
                  <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                    Bank Name
                  </h3>
                </div>
                <div className="mt-3">
                  <div>
                    <input
                      placeholder="Bob Bank"
                      type="bank_name"
                      name="bank_name"
                      value={userInfo?.bank_name}
                      disabled={userInfo?.bank_name ? true : false}
                      className="w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                    />
                  </div>
                </div>
              </div>
            </div>
            )}

{userInfo?.gateway_name && (
            <div className="w-full md:w-1/2">
              <div className="m-3">
                <div>
                  <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                    Gateway Name
                  </h3>
                </div>
                <div className="mt-3">
                  <div>
                    <input
                      placeholder="Bob Bank"
                      type="bank_name"
                      name="bank_name"
                      value={userInfo?.gateway_name}
                      disabled={userInfo?.gateway_name ? true : false}
                      className="w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                    />
                  </div>
                </div>
              </div>
            </div>
            )}

            <div className="w-full md:w-1/2">
              <div className="m-3">
                <div>
                  <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                    Type of Branch
                  </h3>
                </div>
                <div className="mt-3">
                  <div>
                    {/* <select className="w-full border border-[#dfdfdf] focus:border-[#dfdfdf] focus:outline-none p-[0.35rem] cursor-pointer rounded-sm">
                      <option>--Select Branch--</option>

                      <option value={"admin"} className="text-lg">
                        Admin
                      </option>
                      <option value={"branch"} className="text-lg">
                        Branch
                      </option>
                    </select> */}
                    <input
                    placeholder="bobbank"
                    value={userInfo?.type}
                    disabled={userInfo?.type ? true : false}
                    name="manager_name"
                    className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                  />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap ">
            {userInfo?.ifsc_code && (
            <div className="w-full md:w-1/2">
              <div className="m-3">
                <div>
                  <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                    Branch Code / IFSC
                  </h3>
                </div>
                <div className="mt-3">
                  <input
                    placeholder="branchCode"
                    value={userInfo?.ifsc_code}
                    disabled={userInfo?.ifsc_code ? true : false}
                    name="ifsc_code"
                    className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                  />
                </div>
              </div>
            </div>
            )}

{userInfo?.marchant_vpa && (
            <div className="w-full md:w-1/2">
              <div className="m-3">
                <div>
                  <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                    Merchant VPA
                  </h3>
                </div>
                <div className="mt-3">
                  <input
                    placeholder="marchant_vpa"
                    value={userInfo?.marchant_vpa}
                    disabled={userInfo?.marchant_vpa ? true : false}
                    name="marchant_vpa"
                    className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                  />
                </div>
              </div>
            </div>
            )}

{userInfo?.gateway_licence_no && (
            <div className="w-full md:w-1/2">
              <div className="m-3">
                <div>
                  <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                    Gateway Licence Number
                  </h3>
                </div>
                <div className="mt-3">
                  <input
                    placeholder="gateway_licence_no"
                    value={userInfo?.gateway_licence_no}
                    disabled={userInfo?.gateway_licence_no ? true : false}
                    name="gateway_licence_no"
                    className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                  />
                </div>
              </div>
            </div>
            )}

            

            <div className="w-full md:w-1/2">
              <div className="m-3">
                <div>
                  <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                    Email Id
                  </h3>
                </div>
                <div className="mt-3">
                  <input
                    placeholder="bob@gmail.com"
                    value={userInfo?.email}
                    disabled={userInfo?.email ? true : false}
                    name="email"
                    className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                  />
                </div>
              </div>
            </div>
          </div>


          <div className="flex flex-wrap ">
            {userInfo?.manager_name &&(
            <div className="w-full md:w-1/2">
              <div className="m-3">
                <div>
                  <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                    Manager Name
                  </h3>
                </div>
                <div className="mt-3">
                  <input
                    placeholder="bobbank"
                    value={userInfo?.manager_name}
                    disabled={userInfo?.manager_name ? true : false}
                    name="manager_name"
                    className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                  />
                </div>
              </div>
            </div>
            )}

{userInfo?.gateway_auth_person &&(
            <div className="w-full md:w-1/2">
              <div className="m-3">
                <div>
                  <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                    Gateway Auth Person
                  </h3>
                </div>
                <div className="mt-3">
                  <input
                    placeholder="bobbank"
                    value={userInfo?.gateway_auth_person}
                    disabled={userInfo?.gateway_auth_person ? true : false}
                    name="gateway_auth_person"
                    className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                  />
                </div>
              </div>
            </div>
            )}

            {userInfo?.contact_number && (
            <div className="w-full md:w-1/2">
              <div className="m-3">
                <div>
                  <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                    Contact No
                  </h3>
                </div>
                <div className="mt-3">
                  <input
                    placeholder="+91"
                    name="contact_number"
                    value={userInfo?.contact_number}
                    disabled={userInfo?.contact_number ? true : false}
                    className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                  />
                </div>
              </div>
            </div>
            )}

{userInfo?.marchant_address && (
            <div className="w-full md:w-1/2">
              <div className="m-3">
                <div>
                  <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                    Merchant Address
                  </h3>
                </div>
                <div className="mt-3">
                  <input
                    placeholder="+91"
                    name="contact_number"
                    value={userInfo?.marchant_address}
                    disabled={userInfo?.marchant_address ? true : false}
                    className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                  />
                </div>
              </div>
            </div>
            )}
          </div>

          <div className="flex flex-wrap ">
            {userInfo?.manager_name &&(
            <div className="w-full md:w-1/2">
              <div className="m-3">
                <div>
                  <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                    Manager Name
                  </h3>
                </div>
                <div className="mt-3">
                  <input
                    placeholder="bobbank"
                    value={userInfo?.manager_name}
                    disabled={userInfo?.manager_name ? true : false}
                    name="manager_name"
                    className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                  />
                </div>
              </div>
            </div>
            )}

{userInfo?.gateway_auth_person &&(
            <div className="w-full md:w-1/2">
              <div className="m-3">
                <div>
                  <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                    Gateway Auth Person
                  </h3>
                </div>
                <div className="mt-3">
                  <input
                    placeholder="bobbank"
                    value={userInfo?.gateway_auth_person}
                    disabled={userInfo?.gateway_auth_person ? true : false}
                    name="gateway_auth_person"
                    className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                  />
                </div>
              </div>
            </div>
            )}

            {userInfo?.contact_number && (
            <div className="w-full md:w-1/2">
              <div className="m-3">
                <div>
                  <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                    Contact No
                  </h3>
                </div>
                <div className="mt-3">
                  <input
                    placeholder="+91"
                    name="contact_number"
                    value={userInfo?.contact_number}
                    disabled={userInfo?.contact_number ? true : false}
                    className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                  />
                </div>
              </div>
            </div>
            )}

{userInfo?.gateway_contact && (
            <div className="w-full md:w-1/2">
              <div className="m-3">
                <div>
                  <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                    Contact No
                  </h3>
                </div>
                <div className="mt-3">
                  <input
                    placeholder="+91"
                    name="contact_number"
                    value={userInfo?.gateway_contact}
                    disabled={userInfo?.gateway_contact ? true : false}
                    className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                  />
                </div>
              </div>
            </div>
            )}
          </div>

          {/* <div className="flex items-center  justify-end gap-4 mt-8">
                    {loader ? (
                      <button className="bg-gradient-to-r from-[#3C76D5] px-12 to-[#0AB9FC] py-2 rounded-full text-[#fff]">
                        Submitting...
                      </button>
                    ) : (
                      <button
                        onClick={handleSubmit}
                        type="submit"
                        className="bg-gradient-to-r from-[#3C76D5] px-12 to-[#0AB9FC] py-2 rounded-full text-[#fff]"
                      >
                        Submit
                      </button>
                    )}
                  </div> */}
        </div>
      </div>

      {/*  */}
    </div>
  );
};

export default Profile;
