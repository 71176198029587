import { Pagination, Stack } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Switch from "@mui/material/Switch";
import { ChangeOrgStatus } from "../controller/organization";
import { toast } from "react-toastify";

const MerchantTable = ({ TableHeading, sampleData }) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(10); // Set items per page
  const authToken = JSON.parse(localStorage.getItem("authToken"));
  const token = authToken.token;

  useEffect(() => {
    setData(sampleData);
  }, [sampleData]);

  useEffect(() => {
    const filteredData = sampleData?.filter((item) =>
      Object.values(item).some((val) =>
        String(val).toLowerCase().includes(searchTerm.toLowerCase())
      )
    );

    // Calculate pagination
    const startIndex = (currentPage - 1) * perPage;
    const endIndex = startIndex + perPage;
    const paginatedData = filteredData?.slice(startIndex, endIndex);

    setData(paginatedData);
  }, [searchTerm, currentPage, perPage]);



  const ChangeStatus = async(id, status) => {
    const status1 = status ? "active" : "inactive";

 
   await ChangeOrgStatus(token, id, status1)
      .then((res) => {
        toast.success(res.message);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };



  // Pagination handler
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const label = { inputProps: { "aria-label": "Color switch demo" } };

  return (
    <div className="w-full border border-[#efefef] rounded-md mt-5">
      <input
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="mb-4 p-2 border rounded"
      />
      <div className="w-full text-center overflow-x-auto">
        <table className="w-full border border-[#dfdfdf] shadow-xl table_scroll_bar">
          <thead>
            <tr>
              {TableHeading?.map((item, index) => (
                <th
                  key={index}
                  className="border whitespace-nowrap py-3 px-4 rounded-t-3xl"
                >
                  {item}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.length > 0 ? (
              data?.map((item, index) => (
                <tr
                  key={index}
                  className={`${
                    index % 2 === 0 ? "bg-[#fff] shadow-md" : "bg-[#eff1f7]"
                  } h-12`}
                >
                  <td className="border p-2">{index + 1}</td>
                  <td
                    onClick={() => {
                      navigate(`/superadmin/add-new-user`, {
                        state: { id: item?._id, tabId: 3 },
                      });
                    }}
                    className="border p-2"
                  >
                    {item?.merchant_name}
                  </td>
                  {/* <td className="border p-2">{moment(item.createdAt).format("DD-MM-YYYY")}</td> */}
                  <td className="border p-2">{item?.mid}</td>
                  <td className="border p-2">{item?.marchant_vpa}</td>
                  <td className="border p-2">{item?.marchant_address}</td>
                  <td className="border p-2">{item?.city}</td>
                  <td className="border p-2">{item?.state}</td>
                  <td className="border p-2">{item?.pincode}</td>
                  <td className="border p-2">{item?.country}</td>
                  <td className="border p-2">
                    <Switch {...label}  defaultChecked={item.status ===  'active' ? true:false} onChange={(e) => {
                        e.preventDefault();
                        ChangeStatus(item?._id, e.target.checked);
                      }} />
                  </td>
                  <td className="border p-2">{item.url_website}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="w-full text-center" colSpan="6">
                  No data Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      {data?.length > 0 && (
        <div className="mt-4">
          {sampleData?.length > perPage && (
            <div className="mt-4 flex justify-center items-center gap-5">
              <Stack spacing={2}>
                <Pagination
                  count={Math.ceil(
                    sampleData.filter((item) =>
                      Object.values(item).some((val) =>
                        String(val)
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      )
                    )?.length / perPage
                  )}
                  page={currentPage}
                  onChange={handlePageChange}
                  variant="outlined"
                  shape="rounded"
                  boundaryCount={2}
                  siblingCount={2}
                  hidePrevButton={currentPage === 1}
                  hideNextButton={
                    currentPage ===
                    Math.ceil(
                      sampleData?.filter((item) =>
                        Object.values(item).some((val) =>
                          String(val)
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        )
                      )?.length / perPage
                    )
                  }
                />
              </Stack>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MerchantTable;
