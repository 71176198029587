import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { AddBankValidations } from "../../data/validations";
import { toast } from "react-toastify";
import {
  AddBank,
  GetAllBanks,
  GetSingleOrganization,
} from "../../controller/organization";
import { useNavigate } from "react-router-dom";
import ReactCustomSelect from "../ui/ReactCustomSelect";

function Bank({ id }) {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [allBanks, setAllBanks] = useState();
  const [loader, setLoader] = useState(false);
  const authToken = JSON.parse(localStorage.getItem("authToken"));
  const token = authToken.token;
  

  useEffect(() => {
    if (id) {
      setLoader(true);
      GetSingleOrganization(token, id)
        .then((res) => {
          // console.log(res?.result, "response");

          setData(res?.result);

          setLoader(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  useEffect(() => {
    GetAllBanks(token)
      .then((res) => {
        // console.log("res==-->>", res);
        setAllBanks(res.bank);
        // setLoader(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);


  const bank = allBanks?.find(
    (item) =>
      item?.bankname === data?.bank_name
  );


  // console.log(authToken.user.role)
  const AddBankDetails = (values, { setFieldError, resetForm }) => {
    setLoader(true);
    AddBank(id, values, token)
      .then((res) => {
        setLoader(false);
        toast.success(res.message);
        // resetForm({});
        {
          authToken.user.role == "SUPER_ADMIN"
            ? navigate("/superadmin/org-management")
            : navigate("/admin/user-management");
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
        toast.error(error?.response?.data?.message);
        setFieldError(error.message);
      });
  };

  const customStyles = (hasError) => ({
    control: (provided, state) => ({
      ...provided,
      borderColor: hasError ? "red" : provided.borderColor,
      boxShadow: state.isFocused ? 0 : 0,
    }),
  });

  return (
    <div>
      <div className="mt-4 px-4">
        {loader ? (
          <p>Loading.....</p>
        ) : (
          <Formik
            initialValues={{
              bank_name: data?.bank_name ? data?.bank_name : authToken?.user?.bank_name,
              type: data?.type ? data?.type : "",
              ifsc_code: data?.ifsc_code ? data?.ifsc_code : "",
              email: data?.email ? data?.email : "",
              manager_name: data?.manager_name ? data?.manager_name : "",
              location: data?.location ? data?.location : "",
              branch_location: data?.branch_location
                ? data?.branch_location
                : "",
              contact_number: data?.contact_number ? data?.contact_number : "",
            }}
            onSubmit={AddBankDetails}
            validationSchema={AddBankValidations}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              setFieldError,
              values,
              errors,
              touched,
            }) => (
              <div className="border border-[#dfdfdf] rounded-xl p-4">
                <div>
                  <div className="flex flex-wrap">
                    <div className="w-full md:w-1/2">
                      <div className="m-3">
                        <div>
                          <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                            Bank Name
                          </h3>
                        </div>
                        <div className="mt-3">
                          

                          {authToken.user.role === "SUPER_ADMIN" ? (
                            <ReactCustomSelect
                              styles={customStyles(errors?.section?.message)}
                              options={allBanks}
                              defaultValue={bank}
                              multiSelect={true}
                              onchange={(item) =>
                                setFieldValue("bank_name", item.bankname)
                              }
                            />
                          ) : (
                            <div>
                              <input
                                placeholder="Bob Bank"
                                type="bank_name"
                                name="bank_name"
                                onChange={handleChange("bank_name")}
                                onBlur={handleBlur("bank_name")}
                                value={values.bank_name}
                                disabled
                                className="w-full bg-[#fff] placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                              />
                              {errors.bank_name && touched.bank_name ? (
                                <p className="text-[#ef4444]">
                                  {errors.bank_name}
                                </p>
                              ) : null}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="w-full md:w-1/2">
                      <div className="m-3">
                        <div>
                          <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                            Type of Branch
                          </h3>
                        </div>
                        <div className="mt-3">
                          <div>
                            <select
                              defaultValue={values?.type}
                              onChange={(e) =>
                                setFieldValue("type", e.target.value)
                              }
                              className="w-full border border-[#dfdfdf] focus:border-[#dfdfdf] focus:outline-none p-[0.35rem] cursor-pointer rounded-sm"
                            >
                              <option>--Select Branch--</option>

                              {authToken.user.role == "SUPER_ADMIN" && (
                                <option value={"admin"} className="text-lg">
                                  Admin
                                </option>
                              )}
                              <option value={"branch"} className="text-lg">
                                Branch
                              </option>
                            </select>

                            {errors.type && touched.type ? (
                              <p className="text-[#ef4444]">{errors.type}</p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-wrap ">
                    <div className="w-full md:w-1/2">
                      <div className="m-3">
                        <div>
                          <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                            Branch Code / IFSC
                          </h3>
                        </div>
                        <div className="mt-3">
                          <input
                            placeholder="branchCode"
                            name="ifsc_code"
                            onChange={handleChange("ifsc_code")}
                            onBlur={handleBlur("ifsc_code")}
                            value={values.ifsc_code}
                            className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                          />
                          {errors.ifsc_code && touched.ifsc_code ? (
                            <p className="text-[#ef4444]">{errors.ifsc_code}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="w-full md:w-1/2">
                      <div className="m-3">
                        <div>
                          <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                            Email Id
                          </h3>
                        </div>
                        <div className="mt-3">
                          <input
                            placeholder="bob@gmail.com"
                            name="email"
                            onChange={handleChange("email")}
                            onBlur={handleBlur("email")}
                            value={values.email}
                            className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                          />
                          {errors.email && touched.email ? (
                            <p className="text-[#ef4444]">{errors.email}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-wrap ">
                    <div className="w-full md:w-1/2">
                      <div className="m-3">
                        <div>
                          <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                            Manager Name
                          </h3>
                        </div>
                        <div className="mt-3">
                          <input
                            placeholder="bobbank"
                            name="manager_name"
                            onChange={handleChange("manager_name")}
                            onBlur={handleBlur("manager_name")}
                            value={values.manager_name}
                            className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                          />

                          <div>
                            {errors.manager_name && touched.manager_name ? (
                              <p className="text-[#ef4444]">
                                {errors.manager_name}
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="w-full md:w-1/2">
                      <div className="m-3">
                        <div>
                          <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                            Location
                          </h3>
                        </div>
                        <div className="mt-3">
                          <input
                            placeholder="Jaipur"
                            name="location"
                            onChange={handleChange("location")}
                            onBlur={handleBlur("location")}
                            value={values.location}
                            className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                          />
                          <div>
                            {errors.location && touched.location ? (
                              <p className="text-[#ef4444]">
                                {errors.location}
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-wrap ">
                    <div className="w-full md:w-1/2">
                      <div className="m-3">
                        <div>
                          <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                            Branch Location
                          </h3>
                        </div>
                        <div className="mt-3">
                          <input
                            placeholder="Main"
                            name="branch_location"
                            onChange={handleChange("branch_location")}
                            onBlur={handleBlur("branch_location")}
                            value={values.branch_location}
                            className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                          />

                          <div>
                            {errors.branch_location &&
                            touched.branch_location ? (
                              <p className="text-[#ef4444]">
                                {errors.branch_location}
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="w-full md:w-1/2">
                      <div className="m-3">
                        <div>
                          <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                            Contact No
                          </h3>
                        </div>
                        <div className="mt-3">
                          <input
                            placeholder="+91"
                            name="contact_number"
                            onChange={handleChange("contact_number")}
                            onBlur={handleBlur("contact_number")}
                            value={values.contact_number}
                            className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                          />
                          <div>
                            {errors.contact_number && touched.contact_number ? (
                              <p className="text-[#ef4444]">
                                {errors.contact_number}
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center  justify-end gap-4 mt-8">
                    {loader ? (
                      <button className="bg-gradient-to-r from-[#3C76D5] px-12 to-[#0AB9FC] py-2 rounded-full text-[#fff]">
                        Submitting...
                      </button>
                    ) : (
                      <button
                        onClick={handleSubmit}
                        type="submit"
                        className="bg-gradient-to-r from-[#3C76D5] px-12 to-[#0AB9FC] py-2 rounded-full text-[#fff]"
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
}

export default Bank;
