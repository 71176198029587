import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import DashboardHeader from "../components/DashboardHeader";

function Home() {
  return (
    <div className="flex h-screen overflow-x-hidden">
      <div className="w-[25%] h-screen fixed">
        <Sidebar />
      </div>
      <div className="w-[75%] ml-[25%]">
        <div className="h-screen  overflow-y-auto">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Home;
