import React, { useEffect, useState } from "react";
import { GetAllSuperAdminComplaints } from "../../controller/complaints";
import AllSuperAdminComplaints from "../../components/Tables/AllSuperAdminComplaints";
import DashboardHeader from "../../components/DashboardHeader";

function Complaint() {

  const[sampleData,setSampleData] = useState()
  const authToken = localStorage.getItem("authToken");

  if (authToken) {
      try {
          const authData = JSON.parse(authToken);
          var token = authData.token;
          var userId = authData.user_id;

      
      } catch (e) {
          console.error("Error parsing authToken:", e);
      }
  } else {
      console.error("authToken not found in localStorage");
  }

console.log("token==>>>>",userId)

  
  useEffect(() => {
    GetAllSuperAdminComplaints(token)
      .then((data) => setSampleData(data?.data))
      .catch((err) => console.log(err));
  }, []);

  const TableHeading = [
    "S.No.",
    "Complaint Number",
    "Complaint Date",
    "Complaint Generated By",
    "Complaint Category",
    "Complaint Sub Category",
    "Consumer Name",
    "Action",
  ];
  return (
    <div className="bg-[#FAFBFF] overflow-x-hidden">
      {/* Dashboard header start */}
      {/* <div>
        <div className="flex justify-between items-center py-4 px-4 bg-[#FAFBFF]">
          <div>
            <span className="text-sm text-[#707EAE]">
              Pages / Complaint Management
            </span>
            <div>
              <span className="text-xl text-primary font-bold font-i">
                Total Complaints
              </span>
            </div>
          </div>
          <div>
            <div className="bg-[#fff] flex items-center gap-3 p-3 rounded-full">
              <div className="relative">
                <div>
                  <input
                    placeholder="Search"
                    className="border border-[#A3AED0] focus:outline-none rounded-full p-2"
                  />
                </div>
                <div className="absolute top-3 right-3">
                  <CiSearch size={20} className="text-[#A3AED0] font-bold" />
                </div>
              </div>

              <div>
                <IoMdNotificationsOutline
                  size={24}
                  className="text-[#A3AED0]"
                />
              </div>
              <div>
                <FiInfo size={24} className="text-[#A3AED0]" />
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <DashboardHeader />
      {/* Dashboard header end */}

      {/* pagination start */}

      <div className="flex justify-between items-center px-2">
        <div className="flex gap-4 items-center">
          <div className="py-1 px-2 bg-[#fff] border border-[#85868A] rounded-md cursor-pointer">
            <span className="text-sm font-poppins text-[#85868A]">
              Verify Complaint
            </span>
          </div>
          <div className="py-1 px-2 bg-[#fff] border border-[#85868A] rounded-md cursor-pointer">
            <span className="text-sm font-poppins text-[#85868A]">
              Reject Complaint
            </span>
          </div>
        </div>

        {/* <div className="flex items-center gap-4">
          <div className="flex items-center">
            <div>Month</div>
            <div>Year</div>
          </div>

          <Link to="/admin/add-new-complaint">
            <button className="bg-gradient-to-r from-[#3C76D5] to-[#0AB9FC] px-8 py-2 rounded-full text-[#fff]">
              File Complaint
            </button>
          </Link>
        </div> */}
      </div>

      {/* pagination end */}
      <div>
        <div className="px-3">
          <AllSuperAdminComplaints sampleData={sampleData} TableHeading={TableHeading} />
        </div>
      </div>
    </div>
  );
}

export default Complaint;
