import React, { useState } from "react";
import CyberCell from "../../components/organizations/CyberCell";
import PaymentGateway from "../../components/organizations/PaymentGateway";
import Merchant from "../../components/organizations/Merchant";
import Bank from "../../components/organizations/Bank";
import { useLocation } from "react-router-dom";
import DashboardHeader from "../../components/DashboardHeader";

function AddNewUser() {
  const location = useLocation();
  const authToken = JSON.parse(localStorage.getItem("authToken"));
  const id = location?.state?.id;
  const tabNo = location.state.tab;
  const tab = location?.state?.tabId
    ? location?.state?.tabId
    : authToken.user.role == "CYBERCELL" || tabNo == 1
    ? 1
    : authToken.user.role == "PAYMENT" || tabNo == 2
    ? 2
    : authToken.user.role == "MERCHANT" || tabNo == 3
    ? 3
    : 0;
  const [tabs, setTabs] = useState(tab);
  return (
    <div>
      <div className="bg-[#FAFBFF] overflow-x-hidden">
        <div>

          <DashboardHeader
            title={"Pages / Organization Management"}
            subtitle={"Organization Management"}
          />

          <div className="flex gap-4 items-center  px-4">
            {tabs === 0
              ? (authToken.user.role === "BANK" ||
                  authToken.user.role === "SUPER_ADMIN") && (
                  <div
                    onClick={() => {
                      setTabs(0);
                    }}
                    className="py-1 px-2 bg-[#fff] border border-[#85868A] rounded-md cursor-pointer"
                  >
                    <span className="text-sm font-poppins text-[#85868A]">
                      Bank
                    </span>
                  </div>
                )
              : tabs === 1
              ? (authToken.user.role === "CYBER" ||
                  authToken.user.role === "SUPER_ADMIN") && (
                  <div
                    onClick={() => {
                      setTabs(1);
                    }}
                    className="py-1 px-2 bg-[#fff] border border-[#85868A] rounded-md cursor-pointer"
                  >
                    <span className="text-sm font-poppins text-[#85868A]">
                      Cyber Cell / Police
                    </span>
                  </div>
                )
              : tabs === 2
              ? (authToken.user.role === "PAYMENT" ||
                  authToken.user.role === "SUPER_ADMIN") && (
                  <div
                    onClick={() => {
                      setTabs(2);
                    }}
                    className="py-1 px-2 bg-[#fff] border border-[#85868A] rounded-md cursor-pointer"
                  >
                    <span className="text-sm font-poppins text-[#85868A]">
                      Payment Gateway
                    </span>
                  </div>
                )
              : tabs === 3
              ? (authToken.user.role === "MERCHANT" ||
                  authToken.user.role === "SUPER_ADMIN") && (
                  <div
                    onClick={() => {
                      setTabs(3);
                    }}
                    className="py-1 px-2 bg-[#fff] border border-[#85868A] rounded-md cursor-pointer"
                  >
                    <span className="text-sm font-poppins text-[#85868A]">
                      Merchant
                    </span>
                  </div>
                )
              : null}
          </div>
        </div>
        {tabs === 0 ? (
          <Bank id={id} />
        ) : tabs === 1 ? (
          <CyberCell id={id} />
        ) : tabs === 2 ? (
          <PaymentGateway id={id} />
        ) : tabs === 3 ? (
          <Merchant id={id} />
        ) : (
          <Bank />
        )}
      </div>
    </div>
  );
}

export default AddNewUser;
