import { Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import Complaint from "./pages/SuperAdmin/complaint_management";
import AddNewComplaint from "./pages/Admin/AddNewComplaint";
import OrgManagement from "./pages/OrgManagement";
import Login from "./pages/Login";
import { AuthProvider } from "./context/AuthContext";
import PrivateRoute from "./components/PrivateRoute";
import AdminDashboard from "./pages/Admin/AdminDashboard";
import AdminComplaintManagement from "./pages/Admin/AdminComplaintManagement";
import ChangeNewPassword from "./pages/ChangeNewPassword";
import AdminUserManagement from "./pages/Admin/AdminUserManagement";
import ViewSuperAdminComplaint from "./pages/SuperAdmin/ViewSuperAdminComplaint";
import AddNewUser from "./pages/Admin/AddNewUser";
import ForgotPasswordScreen from "./components/ForgotPassword/ForgotPasswordScreen";
import OTPScreen from "./components/ForgotPassword/OTPScreen";
import NewPasswordScreen from "./components/ForgotPassword/NewPasswordScreen";

function App() {
  return (
    <div>
      <AuthProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/change-old-password" element={<ChangeNewPassword />} />
           
          <Route element={<Home />}>
            <Route
              path="profile"
              element={<PrivateRoute element={<Profile />} />}
            />
            <Route
              path="/superadmin/complaint-management"
              element={<PrivateRoute element={<Complaint />} />}
            />
            <Route
              path="/superadmin/view-complaint"
              element={<PrivateRoute element={<ViewSuperAdminComplaint />} />}
            />
            <Route
              path="/superadmin/org-management"
              element={<PrivateRoute element={<OrgManagement />} />}
            />
            <Route
              path="/superadmin/add-new-user"
              element={<PrivateRoute element={<AddNewUser />} />}
            />

            {/* Admin Routes */}
            <Route
              path="/dashboard"
              element={<PrivateRoute element={<AdminDashboard />} />}
            />
            <Route
              path="/admin/complaint-management"
              element={<PrivateRoute element={<AdminComplaintManagement />} />}
            />

            <Route
              path="/admin/add-new-complaint/"
              element={<PrivateRoute element={<AddNewComplaint />} />}
            />

            <Route
              path="/admin/user-management"
              element={<PrivateRoute element={<AdminUserManagement />} />}
            />
            <Route
              path="/admin/add-new-user"
              element={<PrivateRoute element={<AddNewUser />} />}
            />
          </Route>
          <Route path="*" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPasswordScreen />} />
          <Route path="/verify-otp" element={<OTPScreen />} />
          <Route path="/new-password" element={<NewPasswordScreen />} />
        </Routes>
      </AuthProvider>
    </div>
  );
}

export default App;
